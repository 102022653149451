import React, { useEffect, useState, useRef } from 'react'
//fetchNutritionList, fetchDiseaseList,
import {  isUserProfileAvailable } from '../API/FetchAllDetailsAPI'
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import showMessageInfo, { showWarningMessage } from '../Components/showMessage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import { differenceInCalendarYears } from 'date-fns';
import { format } from 'date-fns/esm';
import HomeIcon from '@mui/icons-material/Home';
import '../Components/UpdateProfile.css'; 
import { fetchUserStatus, handleCheckInactive } from '../API/FetchAllDetailsAPI';


const UpdateProfile = () => {
    const userName = localStorage.getItem('user')
    var Gender = ['Select Gender','Male', 'Female', 'Other']
    //var FoodType = ['Select Food Type','Vegetarian', 'Non-Vegetarian']
    const navigate = useNavigate();
    const [selectedGender, setSelectedGender] = useState('Select Gender')
    //const [selectedFoodType, setFoodType] = useState('Select Food Type')
    // const [mobile, setMobile] = useState('');
    // const [age, setAge] = useState('')
    // const [profession, setProfession] = useState('')
    const [height, setHeight] = useState('')
    const [weight, setWeight] = useState('')
    // const [chestSize, setChestSize] = useState('')
    // const [waistSize, setWaistSize] = useState('')
    // const [hipSize, setHipSize] = useState('')
    // const [waterInTake, setWaterInTake] = useState('')
    // const [wakeUpTime, setWakeUpTime] = useState('')
    // const [coffeeTeaAmount, setCoffeeTeaAmount] = useState('')
    // const [breakfast, setBreakfast] = useState('')
    // const [lunch, setLunch] = useState('')
    // const [dinner, setDinner] = useState('')
    // const [bedTime, setBedTime] = useState('')
    // const [healthProblem, setHealthProblem] = useState([]);
    // const [targetNutrition, setTargetNutrition] = useState([]);
    // const [medication, setMedication] = useState('')
    // const [diseases, setDiseases] = useState([])
    // const [nutritions, setNutritions] = useState([])
    // const [isUserProfileUpdated, setIsUserProfileUpdated] = useState();
    // const [healthInputValue, setHealthInputValue] = useState('');
    // const [targetInputValue, setTargetInputValue] = useState('');
    // const [dob, setDOB] = useState('1990-01-01')
    // const [selectedImage, setSelectedImage] = useState(null);
    // const [imagePreview, setImagePreview] = useState(null);

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedImage(file);

  //   // Create a URL for the selected image and set it as a preview
  //   const previewUrl = URL.createObjectURL(file);
  //   setImagePreview(previewUrl);
  // };
    
    // Refs for all input fields
    const selectedGenderRef = useRef(null);
    //const selectedFoodTypeRef = useRef(null);
    // const mobileRef = useRef(null);
    // const dobRef = useRef(null);
    // const professionRef = useRef(null);
    const heightRef = useRef(null);
    const weightRef = useRef(null);
    // const chestSizeRef = useRef(null);
    // const waistSizeRef = useRef(null);
    // const hipSizeRef = useRef(null);
    // const waterInTakeRef = useRef(null);
    // const wakeUpTimeRef = useRef(null);
    // const coffeeTeaAmountRef = useRef(null);
    // const breakfastRef = useRef(null);
    // const lunchRef = useRef(null);
    // const dinnerRef = useRef(null);
    // const bedTimeRef = useRef(null);
    // const medicationRef = useRef(null);
    // const healthProblemRef = useRef(null);
    // const targetNutritionRef = useRef(null)
    const [formValid, setFormValid] = useState(true);

    useEffect(()=>{
      
      
      async function fetchDiseaseAndNutrition(){
        //disease, nutritions,
        const [ userExistingData] = await Promise.all([
          // fetchDiseaseList(),
          // fetchNutritionList(),
          isUserProfileAvailable(localStorage.getItem('user'))
          
        ]);  
        // fetch(`https://orders.baleenmedia.com/API/Wellness/ImageUpload.php/get?JsonUserName=${userName}`)
        //     .then(response => response.blob())
        //     .then(data => {
        //         console.log(data)
        //         setSelectedImage(data)
        //         const imageUrl = URL.createObjectURL(data);
        //         setImagePreview(imageUrl);
        // });
        
        
         // Check if userExistingData is not 'No' and has 'EnquirerPhoneNo'
        if (userExistingData !== 'No') {
          setSelectedGender(userExistingData['Gender'] || 'Select Gender');
          // setMobile(userExistingData['EnquirerPhoneNo']);
          // setDOB(userExistingData['DOB'])
          // setAge(userExistingData['Age'])
          // setProfession(userExistingData['Profession'])
          setHeight(userExistingData['InitialHeight'])
          setWeight(userExistingData['InitialWeight'])
          // setChestSize(userExistingData['InitialChest'])
          // setWaistSize(userExistingData['InitialWaist'])
          // setHipSize(userExistingData['InitialHip'])
          // setWaterInTake(userExistingData['InitialWaterIntake(in ltrs)'])
          // setFoodType(userExistingData['FoodType'])
          // setWakeUpTime(userExistingData['InitialWakeUpTime'])
          // setCoffeeTeaAmount(userExistingData['NoOfCoffeeTea(in Cups)'])
          // setBreakfast(userExistingData['InitialBreakfastTime'])
          // setLunch(userExistingData['InitialLunchTime'])
          // setDinner(userExistingData['InitialDinnerTime'])
          // setBedTime(userExistingData['InitialBedTime'])
          // setHealthProblem([...healthProblem, userExistingData['HealthProblems']])
          // setMedication(userExistingData['Medication'])
          // setTargetNutrition([...targetNutrition ,userExistingData['TargetNutrition']])
        // setIsUserProfileUpdated(userExistingData);
        }
        // setDiseases(disease)
        // setNutritions(nutritions)
      }
      fetchDiseaseAndNutrition()
      setFormValid(true);
    },[])

  //   function handleUpload() {
  //     const formData = new FormData();
  //     formData.append('image', selectedImage);
  //     formData.append('JsonUserName', userName);
  //     fetch('https://orders.baleenmedia.com/API/Wellness/ImageUpload.php', {
  //       method: 'POST',
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data)
  //       })
  //       .catch((error) => {
  //         // Handle any errors.
  //         console.log(error)
  //       });
  // }

    const UpdateData = async(e) => {
      e.preventDefault()

      // clearHighlights();

      if(selectedGender !== 'Select Gender' &&
      // mobile !== '' &&
      // dob !== '1990-01-01' &&
      // profession.trim() !== '' &&
      height.toString().trim() !== '' &&
      weight.toString().trim() !== ''
      // selectedFoodType !== 'Select Food Type' &&
      // chestSize.trim() !== ''&&
      // waistSize.trim() !== ''&&
      // hipSize.trim() !== ''&&
      // waterInTake.trim() !== ''&&
      // wakeUpTime.trim() !== ''&&
      // coffeeTeaAmount.trim() !== ''&&
      // breakfast.trim() !== ''&&
      // lunch.trim() !== ''&&
      // dinner.trim() !== ''&&
      // bedTime.trim() !== ''&&
      // healthProblem.length !== 0 &&
      // targetNutrition.length !== 0 &&
      //medication.trim() !== ''
      ){
        
        if (parseInt(height) < 75) {
          showWarningMessage('Height should be in cm, not inches. Please enter a valid height.');
          heightRef.current.classList.add('error-highlight');
          return; // Stop further processing
        } else {
          heightRef.current.classList.remove('error-highlight'); // Remove highlight if condition is met
        }
        

        if (parseInt(weight) < 10) {
          showWarningMessage('Minimum weight is 10 kg');
          weightRef.current.classList.add('error-highlight');
          return; // Stop further processing
        } else {
          weightRef.current.classList.remove('error-highlight');
        }

        // if (parseInt(profession) === null) {
        //   showWarningMessage('Please enter your profession');
        //   weightRef.current.classList.add('error-highlight');
        //   return; // Stop further processing
        // } else {
        //   weightRef.current.classList.remove('error-highlight');
        // }

        let morningTimestamp = new Date();
        morningTimestamp.setHours(5,0,0,0)
        const targetWakeupTime = moment(morningTimestamp).format('YYYY-MM-DD HH:mm:ss');

        let weightPostingTime = new Date(targetWakeupTime);
        weightPostingTime.setMinutes(weightPostingTime.getMinutes() + 30);
        const targetWeightPostingTime = moment(weightPostingTime).format('YYYY-MM-DD HH:mm:ss')

        let targetafresh1Time = new Date(targetWakeupTime);
        targetafresh1Time.setMinutes(targetafresh1Time.getMinutes() + 45);
        const afresh1Time = moment(targetafresh1Time).format('YYYY-MM-DD HH:mm:ss')

        let targetEducationTime = new Date();
        targetEducationTime.setHours(7,0,0,0);
        const educationTime = moment(targetEducationTime).format('YYYY-MM-DD HH:mm:ss')

        let breakfastTime = new Date(targetWakeupTime);
        breakfastTime.setHours(breakfastTime.getHours() + 2.5);
        const targetBreakfastTime = moment(breakfastTime).format('YYYY-MM-DD HH:mm:ss')

        let targetAfresh2Time = new Date(targetWakeupTime);
        targetAfresh2Time.setHours(targetAfresh2Time.getHours() + 6);
        const afresh2Time = moment(targetAfresh2Time).format('YYYY-MM-DD HH:mm:ss')

        let lunchTime = new Date(targetWakeupTime);
        lunchTime.setHours(lunchTime.getHours() + 8);
        const targetLunchTime = moment(lunchTime).format('YYYY-MM-DD HH:mm:ss')

        let targetAfresh3Time = new Date(targetWakeupTime);
        targetAfresh3Time.setHours(targetAfresh3Time.getHours() + 11);
        const afresh3Time = moment(targetAfresh3Time).format('YYYY-MM-DD HH:mm:ss')

        let targetWorkoutTime = new Date(targetWakeupTime);
        targetWorkoutTime.setHours(targetWorkoutTime.getHours() + 13);
        const workoutTime = moment(targetWorkoutTime).format('YYYY-MM-DD HH:mm:ss')

        let targetDinnerTime = new Date(targetWakeupTime);
        targetDinnerTime.setHours(targetDinnerTime.getHours() + 14.5);
        const dinnerTime = moment(targetDinnerTime).format('YYYY-MM-DD HH:mm:ss')

        let bedTime = new Date(targetWakeupTime);
        bedTime.setHours(bedTime.getHours() + 16.5);
        const targetBedTime = moment(bedTime).format('YYYY-MM-DD HH:mm:ss')
        
        const targetWeight = (((height / 100) * (height / 100)) * 23);
        const targetWaterInTake = (weight/20);
        // const targetChestSize = chestSize;
        // const targetHipSize = chestSize;
        // const targetWaistSize = chestSize;
        //const Disease = healthProblem.toString();
        //const TargetNutrition = targetNutrition.toString();
        await axios.get('https://orders.baleenmedia.com/API/Wellness/InsertEnquiry.php', {
          params:{
            JsonUserName: localStorage.getItem('user'),
            JsonToday: moment().format('YYYY-MM-DD HH:mm:ss'),
            JsonGender: selectedGender,
            // JsonMobNumber: mobile,
            // JsonAge: age,
            // JsonDOB: dob,
            // JsonProfession: profession,
            JsonInitialHeight: height,
            JsonInitialWeight: weight,
            // JsonInitialChest: chestSize,
            // JsonInitialHip: hipSize,
            // JsonInitialWaist: waistSize,
            // JsonInitialWaterInTake: waterInTake,
            // JsonFoodType: selectedFoodType,
            // JsonInitialWakeUpTime: wakeUpTime,
            // JsonNoOfCoffeeTea: coffeeTeaAmount,
            // JsonInitialBreakfastTime: breakfast,
            // JsonInitialLunchTime: lunch,
            // JsonInitialDinnerTime: dinner,
            // JsonInitialBedTime: bedTime,
            // JsonHealthProblems: Disease,
            // JsonMedication: medication,
            JsonTargetWeight: targetWeight,
            JsonTargetWakeUpTime: targetWakeupTime,
            JsonTargetWeightPostingTime: targetWeightPostingTime,
            JsonAfresh1Time: afresh1Time,
            JsonEducationTime: educationTime,
            JsonTargetBreakfastTime: targetBreakfastTime,
            JsonAfresh2Time: afresh2Time,
            JsonTargetLunchTime: targetLunchTime,
            JsonAfresh3Time: afresh3Time,
            JsonWorkoutTime: workoutTime,
            JsonDinnerTime: dinnerTime,
            JsonTargetBedTime: targetBedTime,
            // JsonTargetNutrition: TargetNutrition,
            // JsonTargetHipSize: targetHipSize,
            // JsonTargetChestSize: targetChestSize,
            // JsonTargetWaistSize: targetWaistSize,
            JsonTargetWaterInTake: targetWaterInTake
          }
        })
        .then(response => 
          {
            if(response.status === 200){
              showMessageInfo('Successfully Updated!')
             // handleUpload()
            

            } else {
              console.error(response)
            }
          })
          .catch(error => 
            {
              console.error('Error inserting data', error);
            });
            // checkAndAddToDB('healthProblem')
            // checkAndAddToDB('nutritionProblem')
            navigate('/')
            setFormValid(true);
      }else{
        setFormValid(false);
          // Check if any of the required fields is empty after trimming
          if (
            // selectedGender.trim() === '' ||
            // mobile.toString().trim() === '' ||
            // dob.trim() === '1990-01-01' ||
            // profession.trim() === '' ||
            height.toString().trim() === '' ||
            weight.toString().trim() === '' 
            
          ) {
            showWarningMessage('Please fill all the fields!');
          }

        // Find and focus on the first empty input field and add an error class
        if (selectedGender === 'Select Gender') {
          selectedGenderRef.current.focus();
          selectedGenderRef.current.classList.add('error-highlight');
        // } else if (mobile !== '') {
        //   mobileRef.current.focus();
        //   mobileRef.current.classList.add('error-highlight');
        // } 
        // else if (dob === '1990-01-01') {
         
        //   dobRef.current.focus();
        //   dobRef.current.classList.add('error-highlight');
        // }
        //  else if(profession.trim() === '') {
        //   professionRef.current.focus();
        //   professionRef.current.classList.add('error-highlight');
        } else if(height.toString().trim() === ''){
          heightRef.current.focus()
          heightRef.current.classList.add('error-highlight');
        } else if(weight.toString().trim() === ''){
          weightRef.current.classList.add('error-highlight');}
          weightRef.current.focus()
          
        // } else if(chestSize.trim() !== ''){
        //   chestSizeRef.current.focus()
        //   chestSizeRef.current.classList.add('error-highlight');
        // } else if(waistSize.trim() !== ''){
        //   waistSizeRef.current.focus()
        //   waistSizeRef.current.classList.add('error-highlight');
        // } else if(hipSize.trim() !== ''){
        //   hipSizeRef.current.focus()
        //   hipSizeRef.current.classList.add('error-highlight');
        // } else if(waterInTake.trim() !== ''){
        //   waterInTakeRef.current.focus()
        //   waterInTakeRef.current.classList.add('error-highlight');
        // } else if (selectedFoodType === 'Select Food Type') {
        //   selectedFoodTypeRef.current.focus();
        //   selectedFoodTypeRef.current.classList.add('error-highlight');
        // }else if(wakeUpTime.trim() !== ''){
        //   wakeUpTimeRef.current.focus()
        //   wakeUpTimeRef.current.classList.add('error-highlight');
        // } else if(coffeeTeaAmount.trim() !== ''){
        //   coffeeTeaAmountRef.current.focus()
        //   coffeeTeaAmountRef.current.classList.add('error-highlight');
        // } else if(breakfast.trim() !== ''){
        //   breakfastRef.current.focus()
        //   breakfastRef.current.classList.add('error-highlight');
        // } else if(lunch.trim() !== ''){
        //   lunchRef.current.focus()
        //   lunchRef.current.classList.add('error-highlight');
        // } else if(dinner.trim() !== ''){
        //   dinnerRef.current.focus()
        //   dinnerRef.current.classList.add('error-highlight');
        // } else if(bedTime.trim() !== ''){
        //   bedTimeRef.current.focus()
        //   bedTimeRef.current.classList.add('error-highlight');
        // } else if(healthProblem.trim() !== ''){
        //   healthProblemRef.current.focus()
        //   healthProblemRef.current.classList.add('error-highlight');
        // } else if(targetNutrition.trim() !== ''){
        //   targetNutritionRef.current.focus()
        //   targetNutritionRef.current.classList.add('error-highlight');
        // } else if(medication.trim() !== ''){
        //   medicationRef.current.focus()
        //   medicationRef.current.classList.add('error-highlight');
        // }

        showWarningMessage('Please fill all the fields!')
        
      }
      
    }
    
    // const handleRemove = (index, type) => {
    //   if(type === 'healthProblem'){
    //     const updatedHealthProblem = [...healthProblem];
    //     updatedHealthProblem.splice(index, 1);
    //     setHealthProblem(updatedHealthProblem);
    //   } else {
    //     const updatedTargetNutrition = [...targetNutrition];
    //     updatedTargetNutrition.splice(index, 1);
    //     setTargetNutrition(updatedTargetNutrition)
    //   }
    // };

    // const handleInputChange = (e, type) => {
    //   if(type === 'healthProblem'){
    //     setHealthInputValue(e.target.value);
    //   } else{
    //     setTargetInputValue(e.target.value)
    //   }
    // };

    // const handleKeyPress = (e, type) => {
    //   if (e.key === ' ' && healthInputValue.trim() !== '' && type === 'healthProblem') {
    //     setHealthProblem([...healthProblem, healthInputValue.trim()]);
    //     checkAndAddToDB(type)
    //     setHealthInputValue('');
    //   } else if(e.key === ' ' & targetInputValue.trim() !== '' && type === 'nutritionProblem'){
    //     setTargetNutrition([...targetNutrition, targetInputValue.trim()]);
    //     checkAndAddToDB(type)
    //     setTargetInputValue('')
    //   }
    // };

    // const handleHealthBlur = (type) => {
    //   if (healthInputValue.trim() !== '' && type === 'healthProblem') {
    //     setHealthProblem([...healthProblem, healthInputValue.trim()]);
    //     checkAndAddToDB(type)
    //     setHealthInputValue('');
    //   } else if(targetInputValue.trim() !== ''){
    //     setTargetNutrition([...targetNutrition, targetInputValue.trim()]);
    //     checkAndAddToDB(type)
    //     setTargetInputValue('')
    //   }
    // };

    // const checkAndAddToDB = (type) => {
    //   if(type === 'healthProblem'){
    //     healthProblem.forEach(async(element) => {
    //       // Check if the health problem already exists in diseases
    //     if (!diseases.includes(element)) {
    //       // If it doesn't exist, add it to the diseases array in MySQL
    //       try {
    //         // Make an API call or database query to add the new health problem to MySQL
    //         // Replace the following code with your actual API/database call
    //         await axios.get('https://orders.baleenmedia.com/API/Wellness/InsertDisease.php', {
    //           params:{
    //           JsonUserName: userName,
    //           JsonDisease: element
    //           }
    //         })
    //         .then(response => 
    //           {
    //             if (response.status === 200) {
    //               // If successful, add it to the local diseases array
    //               setDiseases([...diseases, element]);
    //             } else {
    //               // Handle the case where adding to MySQL failed
    //               console.error('Failed to add health problem to MySQL');
    //             }
    //           })
    //           .catch(error => 
    //             {
    //               console.error('Error inserting data', error);
    //             });
    //         // Check if the addition was successful in your response
            
    //       } catch (error) {
    //         // Handle any errors that occur during the API/database call
    //         console.error('Error adding health problem to MySQL:', error);
    //       }
    //     }
    //     });
    //   } else {
    //     targetNutrition.forEach(async(element) => {
    //       if(!nutritions.includes(element)){
    //         try{
    //           await axios.get('https://orders.baleenmedia.com/API/Wellness/InsertNutrition.php',{
    //             params:{
    //               JsonUserName: userName,
    //               JsonNutrition: element
    //             }
    //           })
    //           .then(response => 
    //             {
    //               if (response.status === 200) {
    //                 // If successful, add it to the local nutritions array
    //                 setNutritions([...nutritions, element]);
    //               } else {
    //                 // Handle the case where adding to MySQL failed
    //                 console.error('Failed to add health problem to MySQL');
    //               }
    //             })
    //             .catch(error => 
    //               {
    //                 console.error('Error inserting data', error);
    //               });
    //           // Check if the addition was successful in your response
              
    //         } catch (error) {
    //           // Handle any errors that occur during the API/database call
    //           console.error('Error adding health problem to MySQL:', error);
    //         }
    //       }
    //     })
    //   }
      
    //};
    
    // const findAgeAndSetDOB = (date) => {
    //   const currentAge = differenceInCalendarYears(new Date(), date);
    //   setAge(currentAge)
    //   let formattedDOB = new Date(date)
    //   formattedDOB = format(date, 'yyyy-MM-dd')
    //   setDOB(formattedDOB);
    // }

    const logout = () => {
      navigate('/')
    }

    async function goToHomePage() {
      const userProfileUpdated = await isUserProfileAvailable(localStorage.getItem('user'));
      if(userProfileUpdated === 'No'){
        const result = window.confirm('Please update the profile details! Do you want to logout?')
        if (result){
          localStorage.removeItem('user');
          logout()
        }
      } else{
        navigate('/')
      }
    }

    useEffect(() => {
      handleCheckInactive();
      checkUserStatus();
    }, );
    
    const Logout = (val) => {
      localStorage.removeItem('user');
      window.location = val.link;
    };
  
   const checkUserStatus = async () => {
      try {
        const status = await fetchUserStatus();
        if (status === 'Inactive') {
          window.alert('You are an inactive user. Please contact your coach regarding this.');
          Logout({ link: '/' });
        }
      } catch (error) {
        console.error('Error checking user status:', error);
      }
    };

    return( 
      <div className='update-container'>
        <div style={{marginTop: 10, marginLeft: 30}}>
        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <button onClick={() => {goToHomePage()}} style={{width: 'fit-content', alignItems: 'center', justifyContent:'center', border: 0, backgroundColor: 'black', color: 'white',  padding:10}}>
        <HomeIcon /></button>
        <p style={{marginLeft: 10, textTransform: 'capitalize', fontWeight: 400, fontSize: 14}}>Home</p>
        </span>
      </div>
      <h1 className='normal-label' style={{fontSize: 18, marginTop: 50, textAlign: 'center'}}>Update all your details Here.</h1>
      <form className = 'update-form' action='#'>
      {/* <input type="file" name = "image" accept="image/*" onChange={handleImageChange} style={{justifySelf: 'center'}}/>
       {imagePreview && <img src={imagePreview || URL.createObjectURL(selectedImage)} alt="Preview" style={{ maxWidth: '200px', marginTop: 20, justifySelf: 'center' }} />}
    */}  
        {/* <h2 className='section-heading'>Name</h2>
        <input 
        className='Update-InputBox'
        value={userName}
        readOnly={true}
        /> */}

        <h2 className='section-heading'>Gender</h2>
        <select
          ref={selectedGenderRef}
          value={selectedGender}
          onChange={(e) => setSelectedGender(e.target.value)}
          className='select-gender'
          style={{ alignSelf: 'center', justifySelf: 'center', marginRight: 25, marginBottom: 5 }}
        >
          {Gender.map((member, index) => (
          <option value={member} key={index}>
            {member}
          </option>
        ))}
        </select> 
        
        {/* <h2 className='section-heading'>Mobile Number</h2>
        <input 
          // style={styles.input}
          ref={mobileRef}
          type='number'
          className='Update-InputBox'
          defaultValue={mobile}
          placeholder = "Mobile Number Here"
          onChange={e => setMobile(e.target.value)}
        /> */}
        {/* <span style={{ justifySelf: 'center'}}> */}
        {/* <h2 className='section-heading'>Date Of Birth</h2>
        <input 
          ref={dobRef}
          type='date'
          value={dob}
          className='Update-InputBox'
          max={new Date().toISOString().split('T')[0]}
          style = {{width: 120}}
          onChange={(date)=>{findAgeAndSetDOB(new Date(date.target.value))}}
        /> */}
        {/* </span> */}
        
        {/* <h2 className='section-heading'>Profession</h2>
        <input 
          ref={professionRef}
          className='Update-InputBox'
          placeholder = "Eg: IT"
          defaultValue={profession}
          onChange={e => setProfession(e.target.value)}
        /> */}
        
        <h2 className='section-heading'>Height (in cm)</h2>
        <input 
          className='Update-InputBox'
          ref={heightRef}
          defaultValue={height}
          type='number'
          placeholder = "Eg: 175 cm"
          onChange={e => setHeight(e.target.value)}
        />
        {height.toString().trim() === '' && (
                    <p className="error-message">Height is a required field.</p>
                )}

        <h2 className='section-heading'>Initial Weight (before joining the program)</h2>
        <input 
          className='Update-InputBox'
          ref={weightRef}
          defaultValue={weight}
          type='number'
          placeholder = "Eg: 65 kg"
          onChange={e => setWeight(e.target.value)}
        />
         {weight.toString().trim() === '' && (
                    <p className="error-message">Weight is a required field.</p>
                )}

        {/* <input 
          className='Update-InputBox'
          ref={chestSizeRef}
          defaultValue={chestSize}
          placeholder = "Initial Chest Size (in cm)"
          type='number'
          onChange={e => setChestSize(e.target.value)}
        />
        <input 
          className='Update-InputBox'
          ref={waistSizeRef}
          defaultValue={waistSize}
          placeholder = "Initial Waist Size (in cm)"
          type='number'
          onChange={e => setWaistSize(e.target.value)}
        />
        <input 
          className='Update-InputBox'
          ref={hipSizeRef}
          defaultValue = {hipSize}
          placeholder = "Initial Hip Size(in cm)"
          type='number'
          onChange={e => setHipSize(e.target.value)}
        />
        <input 
          type='number'
          ref={waterInTakeRef}
          className='Update-InputBox'
          defaultValue={waterInTake}
          placeholder = "Initial Water InTake(in Ltrs)"
          step={1}
          max={8}
          onChange={e => setWaterInTake(e.target.value)}
        />
        <select
          value={selectedFoodType}
          ref={selectedFoodTypeRef}
          onChange={(e) => setFoodType(e.target.value)}
          className='select-gender'
          style={{ alignSelf: 'center', justifySelf: 'center', marginRight: 25, marginBottom: 5 }}
        >
          {FoodType.map((member, index) => (
          <option value={member} key={index}>
            {member}
          </option>
          ))}
        </select>
        <span style={{ justifySelf: 'center'}}>
        <label className='normal-label' style={{fontSize: 12, fontWeight: 'normal'}}>Initial Wakeup Time:</label>
        <input 
          className='Update-InputBox'
          ref={wakeUpTimeRef}
          type="time"
          defaultValue={wakeUpTime}
          placeholder = "Initial Wakeup Time"
          onChange={e => setWakeUpTime(e.target.value)}
          style = {{width: 100}}
        />
        </span>
        <input 
          type='number'
          className='Update-InputBox'
          ref={coffeeTeaAmountRef}
          defaultValue={coffeeTeaAmount}
          placeholder = "No. of Coffee/Tea per day (in Cups)"
          onChange={e => setCoffeeTeaAmount(e.target.value)}
          step={1}
        />
        <span style={{ justifySelf: 'center'}}>
        <label className='normal-label' style={{fontSize: 12, fontWeight: 'normal'}}>Initial Breakfast Time:</label>
        <input 
          id='Initial Breakfast Time'
          ref={breakfastRef}
          className='Update-InputBox'
          type="time"
          defaultValue={breakfast}
          onChange={e => setBreakfast(e.target.value)}
          placeholder = "Initial Breakfast Time"
          style = {{width: 100}}
        />
        </span>
        <span style={{ justifySelf: 'center'}}>
        <label className='normal-label' style={{fontSize: 12, fontWeight: 'normal'}}>Initial Lunch Time:</label>
        <input 
          id='Initial Lunch Time'
          ref={lunchRef}
          className='Update-InputBox'
          type="time"
          defaultValue={lunch}
          onChange={e => setLunch(e.target.value)}
          placeholder = "Initial Lunch Time"
          style = {{width: 100}}
        />
        </span>
        <span style={{ justifySelf: 'center'}}>
        <label className='normal-label' style={{fontSize: 12, fontWeight: 'normal'}}>Initial Dinner Time:</label>
        <input 
          id='Initial Dinner Time'
          ref={dinnerRef}
          className='Update-InputBox'
          type="time"
          defaultValue={dinner}
          onChange={e => setDinner(e.target.value)}
          placeholder = "Initial Dinner Time"
          style = {{width: 100}}
        />
        </span>
        <span style={{ justifySelf: 'center'}}>
        <label className='normal-label' style={{fontSize: 12, fontWeight: 'normal'}}>Initial Bed Time:</label>
        <input
          id='InitialBedTime'
          ref={bedTimeRef}
          className='Update-InputBox'
          type="time"
          defaultValue = {bedTime}
          onChange={e => setBedTime(e.target.value)}
          placeholder = "Initial Bed Time"
          style = {{width: 100}}
        />
        </span>
        <textarea 
          id='MedicationList'
          ref={medicationRef}
          className='Update-InputBox'
          type="textarea"
          defaultValue = {medication}
          onChange={e => setMedication(e.target.value)}
          placeholder = "Medications (Use comma to seperate different medicines)"
          maxLength="64"
        />
         
      <input
        ref={healthProblemRef}
        className="Update-InputBox"
        value={healthInputValue}
        onKeyDown={e => handleKeyPress(e, 'healthProblem')} // Handle space key press
        onChange={e => handleInputChange(e, 'healthProblem')}
        onBlur={e => handleHealthBlur('healthProblem')}
        placeholder="Health Problems (Use Space to add more)"
        list="suggestion"
      />
      <datalist id="suggestion">
        {diseases.map((make, index) => {
          return (
            <option
              key={index}
              value={make}
            />
          );
        })}
      </datalist>
      <ul className="bubble-list">
        {healthProblem.map((item, index) => (
          <li key={index} className="bubble">
            {item}
            <button
              className="cancel-button"
              onClick={() => {handleRemove(index, 'healthProblem')}}
            >
              X
            </button>
          </li>
        ))}
      </ul> 
          <input
              id = 'nutritionselect'
              ref={targetNutritionRef}
              list='suggest'
              value={targetInputValue}
              onKeyDown={e => handleKeyPress(e, 'nutritionProblem')} // Handle space key press
              onChange={e => handleInputChange(e, 'nutritionProblem')}
              onBlur={e =>handleHealthBlur('nutritionProblem')}
              className="Update-InputBox"
              placeholder='Target Nutrition (Use Space to add more)'
          />
          <datalist id="suggest">
            {nutritions.map((make, index) => {
              return (
                <option
                  key={index}
                  value={make}
                />
              );
            })}
          </datalist>
          <ul className="bubble-list">
            {targetNutrition.map((item, index) => (
              <li key={index} className="bubble">
                {item}
                <button
                  className="cancel-button"
                  onClick={() => {handleRemove(index, 'targetNutrition')}}
                >
                  X
                </button>
              </li>
            ))}
          </ul>  */}
          {!formValid && (
          <p className="error-message">Please fill all the fields!</p>
        )}
         <button className='login-button' style={{justifySelf: 'center', alignSelf: 'center'}} onClick={UpdateData}>Submit</button> 
         {/* <button name='logout-btn'  style={{fontSize: 14, justifySelf:'center', marginTop: 20}}onClick={Logout}><FontAwesomeIcon icon={faSignOutAlt} style={{ color: 'white', fontSize: '14px' }} />Logout</button> */}
    
        </form>
      </div>
    )
}

export default UpdateProfile;