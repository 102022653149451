import React, {useState, useEffect} from "react";
import '../App.css';
import { Input, message } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { api } from "../API/FetchAllDetailsAPI";
import { useNavigate } from "react-router-dom";
import showMessageInfo, {showErrorMessage} from "../Components/showMessage";
import HomeIcon from '@mui/icons-material/Home';

const ChangePassword = () => {
    const userName = localStorage.getItem('user')
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword,setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const toggleShowPassword = () => {setShowPassword(!showPassword)};
    const navigate = useNavigate()
    const passwordIcon = showPassword ? <EyeOutlined onClick={toggleShowPassword} /> : <EyeInvisibleOutlined onClick={toggleShowPassword} />;

    const FetchPassword = async() => {
        try{
            const response = await api.get("/Get&EditUserPassword.php/get", {
                params: {
                    JsonUserName: userName
                }
            })
            setPassword(response.data);
        }catch(error){
            showErrorMessage("There is no valid user name provided or problem in Internet Connection")
        }
    }

    useEffect(() => {FetchPassword()}, [])

    const authorizePassword = async(event) => {
        event.preventDefault()
        if (currentPassword.trim() === ''|| newPassword.trim() === '' || confirmPassword.trim() === ''){
            setTimeout(() => {
                message.error("Please fill the fields.")
            })
        } else{
            if(password === currentPassword){
                if(password !== newPassword){
                    if(newPassword === confirmPassword){
                        const encodedPassw = encodeURI(newPassword)
                        const response = await api.get(`/Get&EditUserPassword.php/get`,{
                            params:{
                                JsonUserName: userName,
                                JsonNewPassword: encodedPassw
                            }
                        })
                        if(response.data === "Updated Successfully!") {
                            localStorage.setItem('user', userName.trim())
                            showMessageInfo("Password updated Successfully!")
                            setPassword(newPassword)
                            setNewPassword('')
                            setCurrentPassword('')
                            setConfirmPassword('')
                            
                            setTimeout(()=>{
                                navigate('/')
                            },[1000])
                            
                        } else {
                            showErrorMessage("Problem in Changing Password!")
                        }
                    }else {
                        showErrorMessage("Passwords do not match")
                    }
                } else {
                    showErrorMessage("Password should be different from the previous one")
                    }
                }else {
                    showErrorMessage("Please enter current password correctly.")
                }
            } 
        
    }

    const styles = {
        padding: '10px',
        border: '2px solid #ccc',
        borderRadius: '10px',
        fontSize: '16px',
        fontFamily: "'Poppins', sans-serif",
        borderColor: '#2ea93f',
        borderWidth: '2px',
        width: '240px'
      }

    return(
        <div className=" main  flex items-center justify-center min-h-screen  p-1">   
          <div  className="login-form bg-slate-50 p-1 rounded-2xl drop-shadow-xl  ">
            <form className="border p-4 sm:p-14 rounded-xl" >
            <div >
                <span style={{display: 'flex', flexDirection: 'colum', alignItems: 'center'}}>
                <button onClick={() => {navigate('/')}} style={{width: 'fit-content',height:'fit-content', alignItems: 'center', justifyContent:'center', border: 0,borderRadius: 50, backgroundColor: 'black', color: 'white',  padding:10}}>
                <HomeIcon /></button>
                <p style={{marginLeft: 10, textTransform: 'capitalize', fontWeight: 400, fontSize: 14}}>Home</p>
                </span>
            </div>
            <div className=" flex flex-col items-center justify-center">
                <h6 className=" text-center font-semibold text-2xl mb-6 mt-3">CHANGE PASSWORD {userName}</h6>
                <Input.Password 
                    className="Signup-Input "
                    name='CurrentPassword' 
                    defaultValue={currentPassword}
                    placeholder = "Current Password"
                    type={showPassword ? "text" : "password"}
                    onChange={e => setCurrentPassword(e.target.value)}
                    iconRender={() => passwordIcon}
                    style = {styles}
                />
                <Input.Password  
                    className="Signup-Input"
                    name='NewPassword*'
                    defaultValue={newPassword}
                    placeholder = "New Password"
                    type={showPassword ? "text" : "password"}
                    onChange={e => setNewPassword(e.target.value)}
                    iconRender={() => passwordIcon}
                    style = {styles}
                />
                <Input.Password 
                    className="Signup-Input"
                    name='ConfirmPassword'
                    defaultValue={confirmPassword}
                    placeholder = "Confirm Password"
                    type={showPassword ? "text" : "password"}
                    onChange={e => setConfirmPassword(e.target.value)}
                    iconRender={() => passwordIcon}
                    style = {styles}
                />
                <div>
            <button type="submit" className=' mt-5  bg-green-500 hover:bg-green-700 text-white font-bold py-3 sm:py-5 px-4 sm:px-6 rounded-xl text-base sm:text-3xl" '  onClick={authorizePassword}>Confirm</button>
          </div>
          </div>
            </form>
        </div>
    </div>
    )
}

export default ChangePassword;