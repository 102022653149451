import React, { useState, useEffect,useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { NumberOfDays, endDate, userName } from './Main';
import moment from 'moment';
import '../css/Report.css';
import {fetchIndividualDisciplinePercentage , fetchAvgPostedTime, fetchTotalWorkoutKcals, fetchTotalTimeDiscipline, fetchPastDaysTimeDiscipline, fetchReportTeamMembers, fetchUserCoachName, fetchTodaysWeightData,fetchInitialHeightData,fetchInitialWeightData,fetchTargetWeightData, fetchLastAvailableWeightData,fetchWeightReducedData, fetchNeedToAchieveData, fetchTeamMembersOnly,fetchCoachTeamMembers,fetchCoachInactiveTeamMembers } from '../API/FetchAllDetailsAPI';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import HomeIcon from '@mui/icons-material/Home';
import { Paginator } from 'primereact/paginator';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MultiSelect } from 'primereact/multiselect';     

const ReportPage = () => {

    const [Users, setUsers] = useState([])
    const [disciplineData, setDisciplineData] = useState(null);
    const [overallDisciplineData, setOverallDisciplineData ] = useState(null);
    const [todaysWeightData,setTodaysWeightData] = useState([]);
    const [initialHeightData,setInitialHeightData] = useState([]);
    const [initialWeightData,setInitialWeightData] = useState([]);
    const [targetWeightData,setTargetWeightData] = useState([]);
    const [lastAvailableWeight, setLastAvailableWeight] = useState([]);
    const [weightReduced,setWeightReduced] = useState([]);
    const [needToAchieve,setNeedToAchieve] = useState([]);
    const [selectedDays, setSelectedDays] = useState('')
    const navigate = useNavigate();
    const [isFilterAll, setIsFilterAll] = useState(false)
    const importedDays = NumberOfDays;
    const [activeMembersCount,setActiveMembersCount] = useState(0);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [filteredTeamMembers, setFilteredTeamMembers] = useState([])
    const searchfilter = useRef(null);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [searchText, setSearchText] = useState("")
    const [totalTeamMembers,setTotalTeamMembers] = useState([]);
    const [coachName, setCoachName] = useState([]);
    const additionalDays = ['Overall']; // Add your new options here
    const allDays = [...importedDays,...additionalDays];
    // const [showAdditionalData, setShowAdditionalData] = useState(false); 
    const [WorkoutKcalsData, setWorkoutKcalsData] = useState(null);
    // const [teamMembersData, setTeamMembersData] = useState(null);
    const [avgPostedTimes, setAvgPostedTimes] = useState({
      Breakfast: null,
      Education: null,
      Workout: null,
      Lunch: null,
      Dinner: null
    });
    const [disciplinePercentages, setDisciplinePercentages] = useState({
      Education: null,
      Breakfast: null,
      Lunch: null,
      Dinner: null,
      Weight: null
    });
    const [deviationFromDisciplinePercentage, setDeviationFromDisciplinePercentage] = useState(null);

    const [first, setFirst] = useState(0); // Index of the first record
    const [rows, setRows] = useState(10); // Number of rows per page
    const searchRef = useRef(null);

    const showMembersList = filteredMembers.length > 0 ? filteredMembers.sort() : Users

    // Paginator onPageChange handler
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        localStorage.setItem('rows',parseInt(event.rows))
        
    };


  useEffect(() => { 
    if (isFilterActive) {
      setTimeout(() => {
          if (searchfilter.current) {
              searchfilter.current.show(); // Show the dropdown after a slight delay
          }
      }, 150);
      setTimeout(() => {
        if(searchRef.current){
          searchRef.current.focus();
        }
      }, 500)
  }},[isFilterActive]);

    useEffect(() => {
      if(!userName){
        navigate('/')
      }
      const rows = localStorage.getItem('rows')
      setRows(rows ? parseInt(rows): 10)
      const selectingDays = localStorage.getItem('selectedDays')
      setSelectedDays(selectingDays ? selectingDays : 'Last 10 Days')
      const filtered = localStorage.getItem('filteredPerson')
      // let filteredData = filtered ? JSON.parse(filtered) : []
      // filteredData = filteredData.concat(Users.filter  (item => !filteredData.some(member => member === item)).sort());
      setFilteredMembers(filtered ? JSON.parse(filtered) : [])
      // setTotalTeamMembers(filteredData)
      
      const fetchTeamMembersCount = async() => {
        let teamCount = '';
        teamCount = await fetchTeamMembersOnly(userName)
        setActiveMembersCount(teamCount);
        let totalTeamCount = [];
        // let InactiveMembers = await fetchCoachInactiveTeamMembers(userName);
        let ActiveMembers = await  fetchCoachTeamMembers (userName);
        totalTeamCount = ActiveMembers;
        totalTeamCount = totalTeamCount.sort()
        setTotalTeamMembers(totalTeamCount);
      }
      fetchTeamMembersCount();
    }, []);

    useEffect(() =>{
      let filteredData = filteredMembers.concat(totalTeamMembers.filter
      (item => !filteredMembers.some(member => member === item)).sort());
      setTotalTeamMembers(filteredData)
    },[filteredMembers])

    useEffect(() => {
      
      const fetchTeamMembersCount = async() => {
        let startValue = first
        let endValue = rows
        if(first === 0){
          endValue = rows - 1
        }
        if(first !== 0){
          startValue = first - 1
        }
        const reportMembers = await fetchReportTeamMembers(userName, startValue, endValue);
        setUsers(reportMembers);
      }
      fetchTeamMembersCount();
    }, [userName, first, rows]);
    // useEffect(()=>{
    //   if(teamMembers){
        
    //   }
    // },[teamMembers])
  
    useEffect(() => {
      let filteredItem = [];
      filteredItem = totalTeamMembers.filter(item => item.toLowerCase().includes(searchText.toLowerCase())); 
      setFilteredTeamMembers(filteredItem)
    }, [totalTeamMembers, searchText])

    useEffect(() => {
      fetchTotalDiscipline();
    }, [showMembersList])

    useEffect(() => {
      if(searchText) {
        setSearchText("");
        searchRef.current.focus();
      }
    },[filteredMembers]);

    useEffect(() => {
      
      // Check if selectedDays has a valid value
      if (selectedDays) {
        localStorage.setItem('selectedDays', selectedDays);
      }
      
      fetchTeamMembersCoach();
      fetchDisciplineData();
      fetchWorkoutKcalsData();
      fetchAvgPostedTimes();
      fetchDisciplinePercentages();
      deviationDisciplinePercentage();
      fetchTodaysWeight();
      fetchInitialHeight();
      fetchInitialWeight();
      fetchTargetWeight();
      fetchLastAvailableWeight();
      fetchWeightReduced();
      fetchNeedToAchieve();
    }, [selectedDays, showMembersList]);
    
    //TodaysWeightColumn
    const fetchTodaysWeight = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const todaysWeightPromises = users.map((user) => fetchTodaysWeightData(user));
        const overallData = await Promise.all(todaysWeightPromises);
        setTodaysWeightData(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setTodaysWeightData([]);
      }
    };

    //InitialHeightColumn
    const fetchInitialHeight = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const initialheightPromises = users.map((user) => fetchInitialHeightData(user));
        const overallData = await Promise.all(initialheightPromises);
        setInitialHeightData(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setInitialHeightData([]);
      }
    };


    const fetchInitialWeight = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const initialWeightPromises = users.map((user) => fetchInitialWeightData(user));
        const overallData = await Promise.all(initialWeightPromises);
        setInitialWeightData(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setInitialWeightData([]);
      }
    };
    // TargetWeightColumn
    const fetchTargetWeight = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const TargetWeightPromises = users.map((user) => fetchTargetWeightData(user));
        const overallData = await Promise.all(TargetWeightPromises);
        setTargetWeightData(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setTargetWeightData([]);
      }
    };
    //LastWeightAvailableColumn
    const fetchLastAvailableWeight = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const LastAvailableWeightPromises = users.map((user) => fetchLastAvailableWeightData(user));
        const overallData = await Promise.all(LastAvailableWeightPromises);
        setLastAvailableWeight(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setLastAvailableWeight([]);
      }
    };
    // WeightReducedColumn
    const fetchWeightReduced = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const WeightReducedPromises = users.map((user) => fetchWeightReducedData(user));
        const overallData = await Promise.all(WeightReducedPromises);
        setWeightReduced(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setWeightReduced([]);
      }
    };
    // DeviationFromtargetWeightColumn
    const fetchNeedToAchieve = async() => {
      try{
        const users = showMembersList.map((item) => item);
        const NeedToAchievePromises = users.map((user) => fetchNeedToAchieveData(user));
        const overallData = await Promise.all(NeedToAchievePromises);
        setNeedToAchieve(overallData);
      }catch (error) {
        console.error('Error fetching overall data',error);
        setNeedToAchieve([]);
      }
    };
    //CoachNameColumn
    const fetchTeamMembersCoach = async() => {
      try {
        const users = showMembersList.map((item) => item);
        const disciplinePromises = users.map((user) => fetchUserCoachName(user));
        const overallData = await Promise.all(disciplinePromises);
        setCoachName(overallData);
      } catch (error) {
        console.error('Error fetching overall data', error);
        setDisciplineData([]); // Handle the error as needed
        setOverallDisciplineData([]);
      }
    };

    const getData = async (user) => {
      var disciplinePercent = '';
      if (selectedDays === 'Overall') {
          disciplinePercent = await fetchTotalTimeDiscipline(user, endDate);
      } else {
       let selectedDate;
       let toDate;
        let today = moment().format('YYYY-MM-DD')
        const subtractDaysFromDate = (date, days) => {
          return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
        };
        
        let NoOfDays = selectedDays.substring(selectedDays.indexOf(" "), selectedDays.lastIndexOf(" "));
        if (NoOfDays.trim() === "1"){
          selectedDate = subtractDaysFromDate(today, 30)
          toDate = today;
        }else if(selectedDays === "As On Today"){
          selectedDate = today
          toDate = today;
        }else if(selectedDays === "From Yesterday"){
          selectedDate = subtractDaysFromDate(today, 1)
          toDate = today;
        }else if(selectedDays === "As On Yesterday"){
          const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
          selectedDate = yesterday;
          toDate = yesterday;
          
        }else{
          selectedDate = subtractDaysFromDate(today, NoOfDays - 1)
          toDate = today;
        }
        const fewDaysDiscipline = await  fetchPastDaysTimeDiscipline(user, selectedDate, toDate)
        disciplinePercent = fewDaysDiscipline
      
    }
      return disciplinePercent;
    };

    
// const fetchUserWeightData = async () => {
//   try {
//     const MembersList = await fetchWeightBasedOnDate(localStorage.getItem('user'));
//     setState(MembersList);
//   } catch (error) {
//     console.error('Error fetching weight data:', error);
//   }
// };
          // const fetchUserWeightData = async (teamMembers) => {
          //   if (teamMembers > 1) {
          //   try {
          //     // const MembersList = await fetchWeightBasedOnDate(localStorage.getItem('user'));
          //     // setState(MembersList);
          //   } catch (error) {
          //     console.error('Error fetching weight data:', error);
          //   }
          // } else {
          //   try {
          //     // const MembersList = await fetchWeightBasedOnCoach(localStorage.getItem('user'));
          //     // setState(MembersList);
          //   } catch (error) {
          //     console.error('Error fetching weight data:', error);
          //   }
           
          // }
          // };
        
          const fetchTotalDiscipline = async () => {
            try {
              const users = showMembersList.map((item) => item);
              const disciplinePromises = users.map((user) => fetchTotalTimeDiscipline(user, endDate));
              const overallData = await Promise.all(disciplinePromises);
              setDisciplineData(overallData);
              setOverallDisciplineData(overallData);
            } catch (error) {
              console.error('Error fetching overall data', error);
              setDisciplineData([]); // Handle the error as needed
              setOverallDisciplineData([]);
            }
          };
          //Discipline%column
          const fetchDisciplineData = async () => {
            const users = showMembersList.map((item) => item);
            const disciplinePromises = users.map((user) => getData(user));
            const disciplineResults = await Promise.all(disciplinePromises);
            setDisciplineData(disciplineResults);
          };
          //DisciplineImprovement%Column 
          const deviationDisciplinePercentage = async () => {
            const users = showMembersList.map((item) => item);
            
            // Fetch individual discipline data for each user
            const disciplinePromises = users.map((user) => getData(user));
            const disciplineResults = (await Promise.all(disciplinePromises)).map(parsePercentage);
            
            // Fetch overall discipline data for each user
            const overallDataPromises = users.map((user) => fetchTotalTimeDiscipline(user, endDate));
            const overallData = (await Promise.all(overallDataPromises)).map(parsePercentage);
            
            // Calculate deviation for each user
            //const deviations = disciplineResults.map((result, index) => result - overallData[index]);
            const deviations = disciplineResults.map((result, index) => {
              const deviation = result - overallData[index];
              return deviation.toFixed(1) + '%'; // Append '%' symbol after formatting
          });
            setDeviationFromDisciplinePercentage(deviations);
        };
        
        // Function to parse percentage strings to numbers
        const parsePercentage = (percentageString) => {
            const percentage = parseFloat(percentageString.replace('%', ''));
            return isNaN(percentage) ? 0 : percentage; // If parsing fails, default to 0
        };
        
        //TotalKcalsBurnedColumn 
          const fetchWorkoutKcalsData = async () => {
            try {
              // Determine the date range based on selected option
              let selectedDate;
              let toDate;
              let today = moment().format('YYYY-MM-DD');
              if (selectedDays === 'Overall') {
                  selectedDate = '2023-02-25'; 
                  toDate = today;
                } else {
                  const subtractDaysFromDate = (date, days) => {
                    return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
                  };
                  
                  let NoOfDays = selectedDays.substring(selectedDays.indexOf(" "), selectedDays.lastIndexOf(" "));
                  if (NoOfDays.trim() === "1"){
                    selectedDate = subtractDaysFromDate(today, 30)
                    toDate = today;
                  }else if(selectedDays === "As On Today"){
                    selectedDate = today
                    toDate = today;
                  }else if(selectedDays === "From Yesterday"){
                    selectedDate = subtractDaysFromDate(today, 1)
                    toDate = today;
                  }else if(selectedDays === "As On Yesterday"){
                    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    selectedDate = yesterday;                                  
                    toDate = yesterday;
                  }else{
                    selectedDate = subtractDaysFromDate(today, NoOfDays - 1)
                    toDate = today;
                  } 
                }
              
              const users = showMembersList.map((item) => item);
              const workoutKcalsPromises = users.map((user) => fetchTotalWorkoutKcals(user, selectedDate, toDate));
              const workoutKcalsResults = await Promise.all(workoutKcalsPromises)
              setWorkoutKcalsData(workoutKcalsResults);
            } catch (error) {
              console.error('Error fetching workout kcals data:', error);
              setWorkoutKcalsData([]); // Handle the error as needed
            }
          };
          //AverageWeightTiming To WorkOutTiming Column
          const fetchAvgPostedTimes = async () => {
            try {
              const users = showMembersList.map((item) => item);
              const activities = ['Breakfast', 'Education', 'Workout', 'Lunch', 'Dinner', 'Weight']; // List of activities
              const updatedAvgPostedTimes = {};
          
              // Determine the date range based on selected option
              let selectedDate;
              let toDate;
              let today = moment().format('YYYY-MM-DD');
              if (selectedDays === 'Overall') {
                  selectedDate = '2023-02-25'; 
                  toDate = today;
                } else {
                  const subtractDaysFromDate = (date, days) => {
                    return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
                  };
                  
                  let NoOfDays = selectedDays.substring(selectedDays.indexOf(" "), selectedDays.lastIndexOf(" "));
                  if (NoOfDays.trim() === "1"){
                    selectedDate = subtractDaysFromDate(today, 30)
                    toDate = today;
                  }else if(selectedDays === "As On Today"){
                    selectedDate = today
                    toDate = today;
                  }else if(selectedDays === "From Yesterday"){
                    selectedDate = subtractDaysFromDate(today, 1)
                    toDate = today;
                  }else if(selectedDays === "As On Yesterday"){
                    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    selectedDate = yesterday;
                    toDate = yesterday;
                  }else{
                    selectedDate = subtractDaysFromDate(today, NoOfDays - 1)
                    toDate = today;
                  } 
                  
                }

              // Fetch average posted time for each activity for each user
              await Promise.all(activities.map(async (activity) => {
                const avgPostedTimesPromises = showMembersList.map((user) => fetchAvgPostedTime(user, activity, selectedDate, toDate));
                const avgPostedTimesResults = await Promise.all(avgPostedTimesPromises);
                avgPostedTimesResults.forEach((avgPostedTime, index) => {
                  if (!updatedAvgPostedTimes[users[index]]) {
                    updatedAvgPostedTimes[users[index]] = {};
                  }
                  updatedAvgPostedTimes[users[index]][activity] = avgPostedTime;
                });
              }));
          
              setAvgPostedTimes(updatedAvgPostedTimes);
            } catch (error) {
              console.error('Error fetching average posted times', error);
            }
          };
        
       
      // Inside the toggleAdditionalData function

// const toggleAdditionalData = async () => {
//   try {
//       let updatedShowAdditionalData = !showAdditionalData; // Toggle the state
//       // Fetch data only when the checkbox is checked
//       if (updatedShowAdditionalData) {
//           // const membersList = await fetchTeamMembersOnly(localStorage.getItem('user'));
//           // setState(membersList); // Update the state with additional data
//           return
//       } else {
//           // If the checkbox is unchecked, reset the state to the default data
//           // setState([]); // Reset the state
//           // fetchUserWeightData(teamMembersData); // Fetch default data again
//       }
//       // Update the showAdditionalData state after data fetching
//       setShowAdditionalData(updatedShowAdditionalData);
//   } catch (error) {
//       console.error('Error fetching data:', error);
//   }
// };

//Fetching Individual Discipline Percentages
const fetchDisciplinePercentages = async () => {
  try {
    const disciplineActivities = ['Education', 'Breakfast', 'Lunch', 'Dinner', 'Weight'];
    const updatedDisciplinePercentages = {};
    const users = showMembersList.map((item) => item);
    let selectedDate;
          let toDate;
          let today = moment().format('YYYY-MM-DD');
          if (selectedDays === 'Overall') {
              selectedDate = '2023-02-25'; 
              toDate = today;
            } else {
              const subtractDaysFromDate = (date, days) => {
                return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
              };
              
              let NoOfDays = selectedDays.substring(selectedDays.indexOf(" "), selectedDays.lastIndexOf(" "));
              if (NoOfDays.trim() === "1"){
                selectedDate = subtractDaysFromDate(today, 30)
                toDate = today;
              }else if(selectedDays === "As On Today"){
                selectedDate = today
                toDate = today;
              }else if(selectedDays === "From Yesterday"){
                selectedDate = subtractDaysFromDate(today, 1)
                toDate = today;
              }else if(selectedDays === "As On Yesterday"){
                const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                selectedDate = yesterday;
                toDate = yesterday;
              }else{
                selectedDate = subtractDaysFromDate(today, NoOfDays - 1)
                toDate = today;
              } 
              
            }

    await Promise.all(disciplineActivities.map(async (activity) => {
      const disciplinePromises = users.map((user) => fetchIndividualDisciplinePercentage(user, selectedDate, toDate, activity));
      const disciplineResults = await Promise.all(disciplinePromises);

      disciplineResults.forEach((percentage, index) => {
        if (!updatedDisciplinePercentages[users[index]]) {
          updatedDisciplinePercentages[users[index]] = {};
        }
        updatedDisciplinePercentages[users[index]][activity] = percentage;
      });
    }));

    setDisciplinePercentages(updatedDisciplinePercentages);
  } catch (error) {
    console.error('Error fetching discipline percentages:', error);
  }
};

const handleFilterButtonClick = () => {
    setIsFilterActive((prevState) => !prevState)
  } 

  const handleSelectionChange = (e) => {
    const selectedValue = e.value.map(value => value)
    setFilteredMembers(selectedValue);
    localStorage.setItem('filteredPerson',JSON.stringify(selectedValue))
  };




    const mappedData = showMembersList.map((item, index) => {
      const weightDisciplinePercentage = disciplinePercentages[item] && disciplinePercentages[item]['Weight'];
      const educationDisciplinePercentage = disciplinePercentages[item] && disciplinePercentages[item]['Education'];
      const breakfastDisciplinePercentage = disciplinePercentages[item] && disciplinePercentages[item]['Breakfast'];
      const lunchDisciplinePercentage = disciplinePercentages[item] && disciplinePercentages[item]['Lunch'];
      const dinnerDisciplinePercentage = disciplinePercentages[item] && disciplinePercentages[item]['Dinner'];
      
      return {
          EntryUser: item,
          CoachName: coachName[index],
          OverallDisciplinePercentage: overallDisciplineData && overallDisciplineData[index],
          DisciplinePercentage: disciplineData && disciplineData[index],
          DeviationFromDisciplinePercent: deviationFromDisciplinePercentage[index],
          WeightDisciplinePercentage: weightDisciplinePercentage !== undefined ? weightDisciplinePercentage.Weight : 0,
          EducationDisciplinePercentage: educationDisciplinePercentage !== undefined ? educationDisciplinePercentage.Education : 0,
          BreakfastDisciplinePercentage: breakfastDisciplinePercentage !== undefined ? breakfastDisciplinePercentage.Breakfast : 0,
          LunchDisciplinePercentage: lunchDisciplinePercentage !== undefined ? lunchDisciplinePercentage.Lunch : 0,
          DinnerDisciplinePercentage: dinnerDisciplinePercentage !== undefined ? dinnerDisciplinePercentage.Dinner : 0,
          InitialHeight: initialHeightData && initialHeightData[index] ,
          InitialWeight: initialWeightData && initialWeightData[index] ,
          TargetWeight: targetWeightData && targetWeightData[index] ,
          TodayWeight: todaysWeightData && todaysWeightData[index] ,
          yesterdayWeight:  lastAvailableWeight && lastAvailableWeight[index] ,
          NeedtoAchieve: needToAchieve && needToAchieve[index] ,
          weightReduced: weightReduced && weightReduced[index] ,
          WorkoutKcalsData: WorkoutKcalsData!== undefined? WorkoutKcalsData[index] : 0,
          WeightPostingTime: avgPostedTimes[item]!== undefined? avgPostedTimes[item]['Weight'] : 0,
          BreakfastPostingTime: avgPostedTimes[item] !== undefined? avgPostedTimes[item]['Breakfast']: 0,
          EducationPostingTime: avgPostedTimes[item] !== undefined?avgPostedTimes[item]['Education']: 0,
          LunchPostingTime: avgPostedTimes[item] !== undefined? avgPostedTimes[item]['Lunch']: 0,
          DinnerPostingTime: avgPostedTimes[item] !== undefined? avgPostedTimes[item]['Dinner']: 0,
          WorkoutPostingTime: avgPostedTimes[item] !== undefined? avgPostedTimes[item]['Workout']: 0,
        };
  });

  const handleSelectAllTeamMembers = () => {
    if(isFilterAll){
      setFilteredMembers([]);
      setIsFilterAll(false)
    }else{
      setFilteredMembers(totalTeamMembers);
      setIsFilterAll(true);
    }
  }

  const headerTemplate = (options) => {
    return (
      
        
        <div className="p-multiselect-header">
        <div id="pr_id_134" className= {isFilterAll ? 'p-checkbox p-component p-highlight' : 'p-checkbox p-component '} data-p-highlight="false" data-p-disabled="true" role="checkbox" data-pc-name="checkbox" data-pc-section="root">
        <input type="checkbox" class="p-checkbox-input" aria-invalid="true" data-pc-section="input" onClick={handleSelectAllTeamMembers} checked={isFilterAll}/>
        <div class="p-checkbox-box" data-p-highlight="false" data-p-disabled="false" data-pc-section="box">
        {isFilterAll ? <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" 
        class="p-icon p-checkbox-icon p-c" aria-hidden="true" data-pc-section="checkboxicon">
          <path d="M4.86199 11.5948C4.78717 11.5923 4.71366 11.5745 4.64596 11.5426C4.57826 11.5107 4.51779 11.4652 4.46827 11.4091L0.753985 7.69483C0.683167 7.64891 0.623706 7.58751 0.580092 7.51525C0.536478 7.44299 0.509851 7.36177 0.502221 7.27771C0.49459 7.19366 0.506156 7.10897 0.536046 7.03004C0.565935 6.95111 0.613367 6.88 0.674759 6.82208C0.736151 6.76416 0.8099 6.72095 0.890436 6.69571C0.970973 6.67046 1.05619 6.66385 1.13966 6.67635C1.22313 6.68886 1.30266 6.72017 1.37226 6.76792C1.44186 6.81567 1.4997 6.8786 1.54141 6.95197L4.86199 10.2503L12.6397 2.49483C12.7444 2.42694 12.8689 2.39617 12.9932 2.40745C13.1174 2.41873 13.2343 2.47141 13.3251 2.55705C13.4159 2.64268 13.4753 2.75632 13.4938 2.87973C13.5123 3.00315 13.4888 3.1292 13.4271 3.23768L5.2557 11.4091C5.20618 11.4652 5.14571 11.5107 5.07801 11.5426C5.01031 11.5745 4.9368 11.5923 4.86199 11.5948Z" fill="currentColor"></path></svg>
        : <></>}
        </div></div>
            <div className="p-multiselect-select-all">
                {/* Render the select all checkbox if needed */}
                {options.selectAllElement}
            </div>
            <div className="p-multiselect-filter-container">
                {/* Render the filter input */}
                {/* {options.filterElement} */}
                <input class="p-multiselect-filter p-inputtext p-component" type="text" role="searchbox" data-pc-name="inputtext" data-pc-section="root" value={searchText} onChange={(e) => {setSearchText(e.target.value)}} ref={searchRef}/>
            </div>
            <button
                type="button"
                className="p-multiselect-close p-link"
                onClick={handleClose}

            >
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="p-icon p-multiselect-close-icon"
                    aria-hidden="true"
                    data-pc-section="closeicon"
                >
                    <path
                        d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </button>
        </div>
        
    );
};

  const handleClose = (e) => {
        setFilteredMembers([]);
        setIsFilterActive(false);
        setIsFilterAll(false);
        localStorage.removeItem('filteredPerson');
        setSearchText("")
  };

 return (
      
      <div className="Home-nav">
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' , marginLeft: 12, padding: 12}}>
        <button onClick={() => navigate('/')} style={{ width: 'fit-content', alignItems: 'center', justifyContent: 'center', border: 0, backgroundColor: 'black', color: 'white', padding: 10, cursor: 'pointer' }}>
          <HomeIcon />
        </button>
        <p style={{ marginLeft: 10, textTransform: 'capitalize', fontWeight: 400, fontSize: 14 }}>Home</p>
      </span>
          <div>
            {isFilterActive && ( 
              <MultiSelect 
                value={filteredMembers} 
                onChange={handleSelectionChange} 
                options={filteredTeamMembers} 
                filter
                placeholder="Search Team Members" 
                maxSelectedLabels={3} 
                className='Dropdown' 
                ref={searchfilter}
                onFocus={() => setTimeout(() => {searchRef.current && searchRef.current.focus()}, 100)}
                aria-labelledby="dd1"
                panelHeaderTemplate={headerTemplate}
                // onHide={() => {setFilteredMembers([]); setIsFilterActive(false)}}
                // onHide={handleClose}
              />)}
    
            <button
            onClick={handleFilterButtonClick} className="filter" style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }} > <FilterAltIcon />
            </button>
          </div> 

         <div className="report-table" style={{ marginLeft: 15}}>
            <h1 style={{fontSize: 20 }}>Team Members</h1>
            <DataTable 
            //</div>value={mappedData} 
            value={mappedData}
            scrollable scrollHeight="600px"> 
                <Column field="EntryUser" header="Members" sortable frozen className="font-bold entry-user-column" style={{columnWidth: '55px' }}/>
                <Column field="CoachName" header="Coach Name" sortable frozen style={{columnWidth: '65px', textAlign: 'left' }} />
                <Column field="OverallDisciplinePercentage" header="Overall Discipline %" sortable style={{columnWidth: '65px'}} />
                <Column field="DisciplinePercentage" header="Discipline %" sortable style={{columnWidth: '65px'}} />
                <Column field="DeviationFromDisciplinePercent" header="Discipline Improvement %" sortable style={{columnWidth: '70px'}} />
                <Column field="WeightDisciplinePercentage" header="Weight Discipline %" sortable style={{columnWidth: '65px'}} body={(rowData) => `${(rowData.WeightDisciplinePercentage ?? 0).toFixed(1)}%`} />
                <Column field="EducationDisciplinePercentage" header="Education Discipline %" sortable style={{columnWidth: '65px'}} body={(rowData) => `${(rowData.EducationDisciplinePercentage ?? 0).toFixed(1)}%`} />
                <Column field="BreakfastDisciplinePercentage" header="Breakfast Discipline %" sortable style={{columnWidth: '65px'}} body={(rowData) => `${(rowData.BreakfastDisciplinePercentage ?? 0).toFixed(1)}%`} />
                <Column field="LunchDisciplinePercentage" header="Lunch Discipline %" sortable style={{columnWidth: '65px'}} body={(rowData) => `${(rowData.LunchDisciplinePercentage ?? 0).toFixed(1)}%`} />
                <Column field="DinnerDisciplinePercentage" header="Dinner Discipline %" sortable style={{columnWidth: '65px'}} body={(rowData) => `${(rowData.DinnerDisciplinePercentage ?? 0).toFixed(1)}%`} />
                <Column field="InitialHeight" header="Initial Height" sortable style={{columnWidth: '55px'}} />
                <Column field="InitialWeight" header="Initial Weight" sortable style={{columnWidth: '65px'}} />
                <Column field="TargetWeight" header="Target Weight" sortable style={{columnWidth: '65px'}} />
                <Column field="TodayWeight" header="Today's Weight" sortable style={{columnWidth: '65px'}}/>
                <Column field="yesterdayWeight" header="Last Weight Available" sortable style={{columnWidth: '65px'}}/>
                <Column field="NeedtoAchieve" header="Deviation from Target Weight" sortable style={{columnWidth: '65px'}}/> 
                <Column field="weightReduced" header="Weight Reduced" sortable style={{columnWidth: '65px'}} />
                <Column field="WorkoutKcalsData" header="Total Kcals Burned " sortable style={{columnWidth: '65px'}}/>
                <Column field="WeightPostingTime" header="Average Weight Time" sortable style={{columnWidth: '65px'}}/>
                <Column field="EducationPostingTime" header="Average Education Time" sortable style={{columnWidth: '65px'}}/>
                <Column field="BreakfastPostingTime" header="Average Breakfast Time" sortable style={{columnWidth: '65px'}}/>
                <Column field="LunchPostingTime" header="Average Lunch Time" sortable style={{columnWidth: '65px'}}/>
                <Column field="DinnerPostingTime" header="Average Dinner Time" sortable style={{columnWidth: '65px'}}/>
                <Column field="WorkoutPostingTime" header="Average Workout Time" sortable style={{columnWidth: '65px'}}/>
            </DataTable>
            {/* Paginator */}          
  <>
  {(totalTeamMembers.length  > 10 && filteredMembers.length === 0) && (
  <Paginator
    first={first}
    rows={rows}
    totalRecords={activeMembersCount}
    onPageChange={onPageChange}
    rowsPerPageOptions={ 
      [10, 20, 30, activeMembersCount].filter((option, index, array) => 
      option <= activeMembersCount && array.indexOf(option) === index)
    
    }
  />
)}

  </>         
            {/* Toggle button */}
            {/* <div style={{ position: 'absolute', top: 30, right: 40, zIndex: 999, visibility: teamMembersData && teamMembersData > 1 ? 'visible' : 'hidden' }}>
                <label style={{ fontSize: '12px' }}>
                    <input
                        type="checkbox"
                        checked={showAdditionalData}
                        onChange={toggleAdditionalData}
                        style={{ width: 'auto', margin: 10 }}
                    />
                    My Team Members 
                </label>
            </div> */}
            <div className='asof-container'>
            <select value={selectedDays} onChange={e => setSelectedDays(e.target.value)} className='over-all' style={{ position: 'absolute' , top: 75 , right: 20 , zIndex: 999 }}>
{/*<select value={selectedDays} onChange={e => setSelectedDays(e.target.value)} className='over-all' style={{ position: 'absolute', top: 75, right: 20, zIndex: 999 }}>
                        {/* Select dropdown for choosing the number of rows per page 
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        {/* Add more options as needed /}
                     </select> */}
                {allDays.map(day => <option value={day} key={day}>{day}</option>) }
            </select>
        </div>
        </div>
        </div>
        
    );
};

export default ReportPage;