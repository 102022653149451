//Bunch of Imports;
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'; // Import a date picker component
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
//import React, { useState } from 'react';

//import useLongPress from './useLongPress';

import '../App.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck,faWeightScale, faBookOpenReader, faBowlRice, faPlateWheat, faUtensils, faDumbbell, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import "react-circular-progressbar/dist/styles.css";
import FetchAllDetailsAPI, { fetchWorkoutKcals, fetchTimeDisciplineBar, fetchTotalTimeDiscipline, fetchPastDaysTimeDiscipline, fetchLastMeasurement, FetchTimeDisciplineAndMeasurement, fetchTeamDiscipline,fetchLineGraph  } from '../API/FetchAllDetailsAPI';
import {format,  subDays, subMonths, setMinutes, setHours} from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import BarChart from '../Components/BarChart';
import { Line } from 'react-chartjs-2';
//import LineChart from '../Components/LineChart';
//import CircleProgressBar from '../Components/CircularProgressBar';
import moment from 'moment';
import { isUserProfileAvailable } from '../API/FetchAllDetailsAPI';
import { useNavigate } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';
import showMessageInfo, { showWarningMessage, showErrorMessage } from '../Components/showMessage';
// import WindowIcon from '@mui/icons-material/Window';
import SideBar from '../Components/SideBar';
// import IdealIcon from '../Images/ideal weight icon.png';
import { fetchUserStatus, handleCheckInactive } from '../API/FetchAllDetailsAPI';

export let endDate = moment().format('YYYY-MM-DD');
export var membersCount
export const userName = localStorage.getItem('user');
const WaterArray = ['Water 1 ltr','Water 2 ltrs','Water 3 ltrs','Water 4 ltrs','Water 5 ltrs','Water 6 ltrs','Water 7 ltrs','Water 8 ltrs']
const AfreshArray = ['Afresh 1 Scoop', 'Afresh 2 Scoop','Afresh 3 Scoop','Afresh 4 Scoop','Afresh 5 Scoop','Afresh 6 Scoop','Afresh 7 Scoop','Afresh 8 Scoop', 'Afresh 9 Scoop','Afresh 10 Scoop']
//Declaring Variables and arrays
export const Activities = ['Education','Breakfast','Lunch','Dinner', 'Water 1 ltr','Water 2 ltrs','Water 3 ltrs','Water 4 ltrs','Water 5 ltrs','Water 6 ltrs','Water 7 ltrs','Water 8 ltrs', 'Afresh 1 Scoop', 'Afresh 2 Scoop','Afresh 3 Scoop','Afresh 4 Scoop','Afresh 5 Scoop','Afresh 6 Scoop','Afresh 7 Scoop','Afresh 8 Scoop', 'Afresh 9 Scoop','Afresh 10 Scoop'];
export const PhyicalActivityList = ['Workout', 'Weight'];
let SelectedDate = moment().format('YYYY-MM-DD');
export const NumberOfDays = ['Last 10 days','As On Today','As On Yesterday','From Yesterday', 'Last 3 days', 'Last 4 days', 'Last 5 days', 'Last 6 days', 'Last 7 days', 'Last 8 days', 'Last 9 days', 'Last 1 Month']
export const AllActivity = ['Education','Breakfast','Lunch','Dinner', 'Water 1 ltr','Water 2 ltrs','Water 3 ltrs','Water 4 ltrs','Water 5 ltrs','Water 6 ltrs', 'Workout', 'Weight', 'Water 7 ltrs','Water 8 ltrs',]
export let today = new Date();
export let thisDay = updateThisDay();
var intRegex =  /^-?\d+$/

// Function to update the thisDay variable with the current date
function updateThisDay() {
  return moment().format('YYYY-MM-DD');
}

//Start of the main module
const Main = () => {
  // const importedDays = NumberOfDays;
  // const additionalDays = ['Overall'];
  //const allDays = [...importedDays,...additionalDays];
  const navigate = useNavigate()
  const [activityTimeData, setActivityTimeData] = useState([]);
  const [buttonPressed, setButtonPressed] = useState(false)
  const [physicalActivities, setPhysicalActivities] = useState('')
  const [measurement, setMeasurement] = useState()
  const [selectedActivityPlaceHolder,setSelectedActivityPlaceHolder] = useState('')
  const [waterLtrs, setWaterLtrs] = useState(0);
  const [afresh, setAfresh] = useState(0);
  const [modal, setModal] = useState(false);
  const [setStackModal] = useState(false); //BEFORE const [stackModal, setStackModal] = useState(false);
  const [currentMoment, setCurrentMoment] = useState(thisDay)
  const [selectedDays, setSelectedDays] = useState('As On Today')
  const [kcals, setKcals] = useState(0)
  const [barData, setBarData] = useState([])
  const [avgFewDaysDiscipline, setAvgFewDaysDiscipline] = useState('')
  const [activityData, setActivityData] = useState([]);
  const [physcialActivityData, setPhyscialActivityData] = useState([]);
  const [totalTimeDiscipline, setTotalTimeDiscipline] = useState('');
  const [fewDaysTimeDiscipline, setFewDaysTimeDiscipline] = useState('');
  const [timeDataArray, setTimeDataArray] = useState({})
  const [postedItem, setPostedItem] = useState([]);
  const [postedPhysicalItem, setPostedPhysicalItem] = useState([])
  const [kgs, setKgs] = useState(0)
  const [previousMeasurement, setPreviousMeasurement] = useState(0)
  const [selectedKcal, setSelectedKcal] = useState([])
  const [overallKcal, setOverallKcal] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedMember, setSelectedMember] = useState(localStorage.getItem('user'))
  const [teamDiscipline, setTeamDiscipline] = useState()
  const [totalTeamDiscipline, setTotalTeamDiscipline] = useState()
  const [showMeasurementError, setShowMeasurementError] = useState(false)
  const [totalTeamMembers, setTotalTeamMembers] = useState([])
  const [idealWeightCrossed, setIdealWeightCrossed] = useState(false);
  const [targetWeight, setTargetWeight] = useState(null);
  const [currentWeight, setCurrentWeight] = useState(null);
  // const [inactiveMessage, setInactiveMessage] = useState('');
  // const [userApproval, setUserApproval] = useState([]);
  const [showLineGraph, setShowLineGraph] = useState(false);
  const [generatedDates, setGeneratedDates] = useState([]); // Initialize dates as an empty array
 const [selectedRange, setSelectedRange] = useState('lastWeek');
  const [trendBarData, setTrendBarData] = useState([])
  const [selectedActivity, setSelectedActivity] = useState('');
  
  // const [customStartDate, setCustomStartDate] = useState(new Date('2023-05-25'));
  // const [customEndDate, setCustomEndDate] = useState(new Date());
  

 const [customStartDate, setCustomStartDate] = useState(subDays(new Date( ), 14));
 const [customEndDate, setCustomEndDate] = useState(new Date());
 const [holdTimer, setHoldTimer] = useState(null);
 
  const Logout = (val) => {
    localStorage.removeItem('user');
    window.location = val.link;
  };

  //--longpress start

  function handleButtonHold(dateRange,activity) {
    setHoldTimer(setTimeout(() => {
      setSelectedActivity(activity);
      setShowLineGraph(true);
      fetchLineData(activity); // Replace with your fetch data function
    }, 800)); // 800 milliseconds = 0.8 seconds
  }

  function handleButtonRelease() {
    clearTimeout(holdTimer);
  }

  function handleNormalClick(activity) {
    InsertActivity(activity); // Replace with your normal click action
    // Example: showUndoPopup();
  }

useEffect(() => {
  fetchLineData(selectedActivity);
}, [selectedRange, customStartDate, customEndDate]);
  //--lomgpress end

  useEffect(() => {
    handleCheckInactive();
    checkUserStatus();

  }, []);

 const checkUserStatus = async () => {
    try {
      const status = await fetchUserStatus();
      if (status && status.Status === 'Inactive') {
        window.alert('You are an inactive user. Please contact your coach regarding this.');
        Logout({ link: '/' });
      }
    } catch (error) {
      console.error('Error checking user status:', error);
    }
  };


  //Function after selecting the physical Activity Button
  const physicalActivityButtonClick = async(PhysicalActivityName) => {
    
    if(localStorage.getItem('user') === selectedMember){
    setPhysicalActivities(PhysicalActivityName)
    if (postedPhysicalItem.includes(PhysicalActivityName) && PhysicalActivityName === "Weight")
    {
      InsertPhysicalActivity(PhysicalActivityName)
    }
    else
    {
      const prevMeasure = await fetchLastMeasurement(localStorage.getItem('user'), PhysicalActivityName);
      setPreviousMeasurement(prevMeasure);
      setModal(true);
      
    }
    setSelectedActivity(PhysicalActivityName);
  }else{
    showMessageInfo("You can't change other's activity");
  }
  
  }

    //fetch initial and target weight data
    const fetchInitialTargetWeight = async () => {
      try {
        const userExistingData = await isUserProfileAvailable(localStorage.getItem('user'));
  
        
        const targetWeight = userExistingData['TargetWeight']
        setTargetWeight(targetWeight);
        const prevMeasure = await fetchLastMeasurement(localStorage.getItem('user'), 'Weight');
        // const idealWeight = [targetWeight - 2, targetWeight -1 , targetWeight, targetWeight + 1, targetWeight + 2 ]
        const isWithinIdealRange = Math.abs(parseFloat(prevMeasure) - parseFloat(targetWeight)) <= 2;

        
      setIdealWeightCrossed(isWithinIdealRange);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

  //Changing the placeholder as per the selected Physical Activity Button
  const PhysicalActivitySelected = () => 
  {
    if(physicalActivities === 'Workout')
    {
      setSelectedActivityPlaceHolder('in kcal')
    } 
    else if(physicalActivities === 'Weight')
    {
      setSelectedActivityPlaceHolder("in kg")
    } 
    else 
    {
      setSelectedActivityPlaceHolder("Select Activity")
    }
  }

  // Function to toggle the modal
  const toggleModal = (modelName) => {
    if(modelName === 'stack'){
      setStackModal((prevState) => !prevState);
    }else{
      setModal((prevState) => !prevState);
    }
  }

  //Function for Increasing and decreasing the amount of water and afresh
  function IncreaseDecreaseWaterAfresh(Item, Type) {
    if(localStorage.getItem('user') === selectedMember){
        if(Item === 'Water') {
            if(Type === 'Inc'){
                if(waterLtrs===8){
                    showMessageInfo('Maximum amount reached');
                    return
                }
                setWaterLtrs(waterLtrs+1)
                InsertActivity(Activities[waterLtrs+4])
            } else if(Type === 'Dec'){
                if(waterLtrs===0){
                    return
                }
                  
                if(waterLtrs === 1){
                    InsertActivity('Water 1 ltr')
                }else{
                    InsertActivity('Water ' + waterLtrs + ' ltrs')
                }
            }
        } else if(Item === 'Afresh'){
            if(Type === 'Inc') {
                let afreshAmount = afresh + 1
                if(afresh===10){
                    showMessageInfo('Maximum amount reached');
                    return
                }
                setAfresh(afreshAmount)
                InsertActivity('Afresh ' + afreshAmount + ' Scoop')
            } else if(Type === 'Dec'){
                if(afresh===0){
                    return
                  }
                  InsertActivity('Afresh ' + afresh  + ' Scoop')
            }
        }
    }else{
        showWarningMessage("You can't change other's activity");
    }
  }

  //function to load the bar chart and average discipline based on the dropdown days
  const findSelectedDays = async() => {
    let selectedDate;
    
    let toDate = moment().format('YYYY-MM-DD');
    if (selectedDays === 'Overall') {
        selectedDate = '2023-02-25'; 
      } else {
        let today = moment().format('YYYY-MM-DD')
        const subtractDaysFromDate = (date, days) => {
          return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
        };
        
        let NoOfDays = selectedDays.substring(selectedDays.indexOf(" "), selectedDays.lastIndexOf(" "));
        if (NoOfDays.trim() === "1"){
          selectedDate = subtractDaysFromDate(today, 30)
          endDate = toDate;
        }else if(selectedDays === "As On Today"){
          selectedDate = today
          endDate = toDate;
        }else if(selectedDays === "From Yesterday"){
          selectedDate = subtractDaysFromDate(today, 1)
          endDate = toDate;
        }else if(selectedDays === "As On Yesterday"){
          const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
          selectedDate = yesterday;
          endDate = yesterday;
          
        }else{
          selectedDate = subtractDaysFromDate(today, NoOfDays - 1)
          endDate = toDate;
        } 
      }
    //linegraphfindselectdays------------------------------------start
    
    //linegraphfindselectdays------------------------------------end
    
    const [fewDaysDiscipline, avgDiscipline, fewDaysWorkout, totalMeasurement, timeTotalDiscipline, teamDisciplineNew] = await Promise.all([fetchTimeDisciplineBar(selectedMember,selectedDate,endDate), fetchPastDaysTimeDiscipline(selectedMember, selectedDate,endDate), fetchWorkoutKcals(selectedMember, selectedDate,endDate), fetchTotalTimeDiscipline(selectedMember,endDate), fetchTimeDisciplineBar(selectedMember, '2023-02-25', endDate), fetchTeamDiscipline(selectedDate, endDate)])
    setFewDaysTimeDiscipline(fewDaysDiscipline);
    setAvgFewDaysDiscipline(avgDiscipline);
    setSelectedKcal(fewDaysWorkout);
    setTotalTimeDiscipline(totalMeasurement);
    setBarData(timeTotalDiscipline);
    setTeamDiscipline(teamDisciplineNew)
   // fetchPastDaysTimeDiscipline(SelectedDate.toISOString().split('T')[0])
  }
  //Setting the values inside the button in an array (This includes adding and removing the items)
  const setArrayItems = async(ActivityName, Item, Type) =>{
    if(Item === 'Time Data'){
        if(Type === 'Add'){
            let Activity = '';
            Activity = ActivityName;
            Activity = Activity.replaceAll(" ", "")
            const currentDate = new Date(); // Get the current date and time
            let ISTDT = new Date().toLocaleString("en-Us", {timeZone: 'Asia/Kolkata'}); //Getting Indian Date & Time
            ISTDT = new Date(ISTDT); // Convert to Date object
            let formattedTime = ''
            if (ActivityName === 'Education'){
            // Set the time components to the Indian current time
            const currentTime = setMinutes(setHours(ISTDT, ISTDT.getHours()), ISTDT.getMinutes());
            formattedTime = format(currentTime, 'hh:mm a');
            } else {
            // Set the time components to the current time
            const currentTime = setMinutes(setHours(currentDate, currentDate.getHours()), currentDate.getMinutes());
            formattedTime = format(currentTime, 'hh:mm a');
            }
            
            setTimeDataArray((prevState) => ({
            ...prevState,
            [Activity]: formattedTime,
            }));
        } else if(Type === 'Remove'){
            let Activity = ''
            Activity = ActivityName;
            Activity = Activity.replaceAll(" ", "")
            const updatedArray = { ...timeDataArray };
            delete updatedArray[Activity];
            setTimeDataArray(updatedArray);
        }
    } else if (Item === 'Activity'){
        if (Type === 'Add'){
            setPostedItem((prevState) => [...prevState, ActivityName]);
        } else if (Type === 'Remove'){
            setPostedItem((prevState) => prevState.filter((value) => value !== ActivityName));
        }
    } else if (Item === 'Physical'){
        if(Type === 'Add'){
            setPostedPhysicalItem((prevState) => [...prevState, ActivityName]);
        } else if (Type === 'Remove'){
            setPostedPhysicalItem((prevState) => prevState.filter((value) => value !== ActivityName));
        }
    }
  }

  useEffect(() => {
    fetchInitialTargetWeight();
  }, [previousMeasurement]);

  //an useEffect to load the app on enter and refresh the screen
  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded) {
      // Perform any logic you need before the reload, if necessary
      sessionStorage.setItem('hasReloaded', true);
      window.location.reload();
    }

    async function fetchData() {
      const userProfileUpdated = await isUserProfileAvailable(userName);
      if(userProfileUpdated === 'No'){
        if(localStorage.getItem('user') === null || localStorage.getItem('user') === '' ){
          window.location.reload()
        }else{
          navigate('/profile')
        }
      }
    }
    fetchData();
    // fetchInitialTargetWeight()
  }, []);

  useEffect(() => {
    setInterval(()=>{
      setCurrentMoment(updateThisDay())
      thisDay = updateThisDay()
    },1000)
  }, [currentMoment])

  //fetching values if any of the button is pressed or another team person is selected
  useEffect(() => {
    //Update States
    const updateTotalTimeDiscipline = (totalTimeDiscipline) => {
      setTotalTimeDiscipline(totalTimeDiscipline)
    }
    const updateFewDaysTimeDiscipline = (timeDiscipline) => {
      setFewDaysTimeDiscipline(timeDiscipline)
    }
    const updateKcals = (Kcals) => {
      setKcals(Kcals)
    }
    const updateKgs = (Kgs) => {
      setKgs(Kgs)
    }
    const updateBarData = (data) => {
      setBarData(data)
    }

    const updateAvgFewDaysDiscipline = (avg) => {
      setAvgFewDaysDiscipline(avg)
    }

    const updateOverallKcal = (kcal) => {
      setOverallKcal(kcal);
    }

    const updateSelectedKcal = (kcal) => {
      setSelectedKcal(kcal);
    }

    const updateTeamDiscipline = (TDiscipline) => {
      setTeamDiscipline(TDiscipline)
    }

    const updateTotalTeamDiscipline = (TTDiscipline) => {
      setTotalTeamDiscipline(TTDiscipline)
    }
    //Function to await and fetch datas
    const fetchData = () => {
      //updateTotalTimeDiscipline
      FetchTimeDisciplineAndMeasurement( updateFewDaysTimeDiscipline, updateAvgFewDaysDiscipline, SelectedDate, updateKcals, updateKgs, updateBarData, updateTotalTimeDiscipline, updateOverallKcal, updateSelectedKcal, selectedMember, updateTeamDiscipline, updateTotalTeamDiscipline);
    };

    //Final function
    fetchData();
    setButtonPressed(false);
  }, [buttonPressed, selectedMember]);

  //UseEffect to fetch function if the physical activity is selected
  useEffect(() => {
    PhysicalActivitySelected();
  },[physicalActivities])

  //UseEffect to be used while another member in a team is selected
  useEffect(()=>{
    //Update States
    const updatePostedTime = (time) => {
      setActivityTimeData(time); // Update the postedTime state
    };

    const updateActivityData = (activityData) => {
      setActivityData(activityData)
    }
    const updatePhysicalActivityData = (physicalActivityData) => {
      setPhyscialActivityData(physicalActivityData)
    }

    const updateWater = (water) => {
      setWaterLtrs(water)
    }

    const updateAfresh = (afresh) => {
      setAfresh(afresh)
    }

    const updateTeamMembers = (member) => {
      setTeamMembers(member)
    }

    const updateTotalTeamMembers = (member) => {
      setTotalTeamMembers(member)
      localStorage.setItem('members',member)
    }

    //Function to await and fetch datas
    const fetchData = async () => {
      FetchAllDetailsAPI(updatePostedTime, updateActivityData, updatePhysicalActivityData, updateWater, updateAfresh, 
        updateTeamMembers, selectedMember, updateTotalTeamMembers);
    };
    
    //Final function
    fetchData();
  },[selectedMember])

  //if anhy of the values of given or changed the time data is fetched from db into an timedata array
  useEffect(()=>{
    setTimeDataArray({
      Education: activityTimeData[0],
      Breakfast: activityTimeData[1],
      Lunch: activityTimeData[2],
      Dinner: activityTimeData[3], 
      Water1ltr: activityTimeData[4],
      Water2ltrs: activityTimeData[5],
      Water3ltrs: activityTimeData[6],
      Water4ltrs: activityTimeData[7],
      Water5ltrs: activityTimeData[8],
      Water6ltrs: activityTimeData[9], 
      Workout: activityTimeData[10], 
      Weight: activityTimeData[11]})
    setPostedItem(activityData)
    setPostedPhysicalItem(physcialActivityData)
  },[activityTimeData, activityData, physcialActivityData])

  //useEffect to call the function if the dropdown values of no. of days is changed
  useEffect(()=>{
    findSelectedDays()
  },[selectedDays, selectedMember])
    
  //Function for Loging out
  // const Logout = () => {
  //   //navigate('/login')
  //   localStorage.removeItem('user')
  //   window.location.reload()
  // }

  useEffect(() => {
    // Fetch the last recorded weight for the current user
    const fetchCurrentWeight = async () => {
      try {
        const lastMeasurement = await fetchLastMeasurement(localStorage.getItem('user'), 'Weight');
        setCurrentWeight(lastMeasurement);
      } catch (error) {
        console.error('Error fetching current weight:', error);
      }
    };

    
    fetchCurrentWeight();
    // Clean up function
    return () => {
      // Cleanup tasks if any
    };
  }, []);
//line graph---- fetching data


  
  const checkUserApproval = async () => {
    try {
        const approvalStatus = await fetchUserStatus();
        if (approvalStatus && approvalStatus.CoachApproved === 1) {
           // setUserApproval(await changeUserApproval(localStorage.getItem('user')));
        }
    } catch (error) {
        console.error('Error checking user status:', error);
    }
};

  //Inserting the activity
  const InsertActivity = async (ActivityName) => 
  {
    
    if(localStorage.getItem('user') === selectedMember){
    // Get current date and time
    const now = new Date();

    // Format the date and time
    const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss');
    if (postedItem.includes(ActivityName))
    {
      vibrate();
      checkUserApproval();
      setButtonPressed(true)
      const result = window.confirm("Is it Ok to undo?");
      if (result) {
        setArrayItems(ActivityName, 'Time Data', 'Remove')
        setArrayItems(ActivityName, 'Activity', 'Remove')
        if(WaterArray.includes(ActivityName)){
          setWaterLtrs(waterLtrs-1)
        }
        if(AfreshArray.includes(ActivityName)){
          setAfresh(afresh-1)
        }
        // code to execute if "Yes" is clicked
      await axios.get(`https://orders.baleenmedia.com/API/Wellness/ChangeButtonStatus.php/get?JsonUserName=${localStorage.getItem('user')}&JsonActivity=${ActivityName}&JsonDate=${thisDay}`)
      .then(response => 
      {
        setButtonPressed(true)
        return response;
      })
      .catch(error => 
      {
        showErrorMessage('Error inserting data', error);
      });
    
      } else {
        return
      }
    }
    else
    {
      //const MainActivities = Activities.slice(0,7)
      vibrate();
      checkUserApproval();
      
      // if(MainActivities.includes(ActivityName))
      // { 
      //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
      //     navigate('/takephoto');
      //   }else{
      //     setTimeout(() => {
      //       message.info("No Camera found");
      //     });
      //   }
      // }    
      setArrayItems(ActivityName, 'Time Data', 'Add')
      setArrayItems(ActivityName, 'Activity', 'Add')
      await axios.get(`https://orders.baleenmedia.com/API/Wellness/InsertActivity.php/get?JsonUserName=${localStorage.getItem('user')}&JsonActivity=${ActivityName}&JsonNow=${formattedDateTime}`)
      .then(response => 
      {
        setButtonPressed(true)
        return response;
      })
      .catch(error => 
      {
        showErrorMessage('Error inserting data', error);
      });
    }
  }else{
      showMessageInfo("You can't change other's activity");
  }
  }
  

  //Insert a physical activity
  const InsertPhysicalActivity = async (PhysicalActivityName) => {
    if(localStorage.getItem('user') === selectedMember){
      // Get current date and time
      const now = new Date();

      // Format the date and time
      const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss');
      var regex = /^[0-9]*(\.[0-9]+)?$/;
      if(PhysicalActivityName === 'Workout'){
        if ((parseFloat(kcals) + parseFloat(measurement)) >= 0){
          regex = intRegex
        }
      }
      var Check_Measurement = regex.test(measurement);
      
      if (postedPhysicalItem.includes(PhysicalActivityName) && PhysicalActivityName === 'Weight')
      {
        vibrate();
        const result = window.confirm("Is it Ok to undo?");
        window.location.reload();
      if (result) {
        setArrayItems(PhysicalActivityName, 'Physical', 'Remove')
        setArrayItems(PhysicalActivityName, 'Time Data', 'Remove')
        await axios.get(`https://orders.baleenmedia.com/API/Wellness/ChangePhysicalActivityButtonStatus.php/get?JsonUserName=${localStorage.getItem('user')}&JsonPhysicalActivity=${PhysicalActivityName}&JsonDate=${thisDay}`)
        .then(response => 
        {
          setButtonPressed(true)
          setPhysicalActivities('')
          return response;
        })
        .catch(error => 
        {
          console.error('Error inserting data', error);
        });
      }else{
        return
      }
      }
      else
      {
        vibrate();
        if(Check_Measurement)
        {
        //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
        // {
        //   navigate('/takephoto');
        // }else{
        //   setTimeout(() => {
        //     message.info("No Camera found");
        //   });
        // }
        setArrayItems(PhysicalActivityName, 'Physical', 'Add')
        setArrayItems(PhysicalActivityName, 'Time Data', 'Add')
          await axios.get(`https://orders.baleenmedia.com/API/Wellness/InsertPhysicalActivity.php/get?JsonUserName=${localStorage.getItem('user')}&JsonPhysicalActivity=${PhysicalActivityName}&JsonMeasurement=${measurement}&JsonNow=${formattedDateTime}`)
          .then((response) => {
            if (response.data === 'No inputs Available.'){
              showErrorMessage(response.data);
        }
        else
        {
          setModal(false)
          setPhysicalActivities('');
        }
        setMeasurement('');
        setButtonPressed(true);
        return response;
        })
        .catch((error) => 
        {
          showErrorMessage('Error inserting data', error);
        });
      }
      else
      {
        setShowMeasurementError(true);
      }
    }
  }else{
       showMessageInfo("You can't change other's activity");
    }
  };

  //comparing the given time and finding whether the user has pressed within the time range or not
  function compareTime(time1, time2) {
    const startTime = moment(time1, "hh:mm a");
    const endTime = moment(time2, "hh:mm a");
    let timeDiff = startTime.subtract(endTime)
    return timeDiff;
  }
 // useEffect(()=>{window.location.reload(true)},[])
 //while long press--give the user status in line graph----------------------------------?
 const fetchLineData = async (selectedActivity) => {
  try {
    const userName = localStorage.getItem('user');
    let startDate = '';
    let endDate = format(new Date(), 'yyyy-MM-dd'); // Default end date is the current date

    // Calculate start date based on selected range
    if (selectedRange === 'lastWeek') {
      const lastWeekStartDate = subDays(new Date(), 7);
      startDate = format(lastWeekStartDate, 'yyyy-MM-dd');
    } else if (selectedRange === 'lastMonth') {
      const lastMonthStartDate = subMonths(new Date(), 1);
      startDate = format(lastMonthStartDate, 'yyyy-MM-dd');
    } else if (selectedRange === 'custom') {
      // Use custom start and end dates
      startDate = format(customStartDate, 'yyyy-MM-dd');
      endDate = format(customEndDate, 'yyyy-MM-dd');
    }

    // Fetch data from the backend
    const lineData = await fetchLineGraph(userName, selectedActivity, startDate, endDate);

    // Process lineData for Workout activity to ensure all dates are included
    let processedLineData;
    if (selectedActivity === 'Workout') {
      // Ensure all dates within the range are included with zero value if no data exists
      processedLineData = generateFullRangeData(lineData, startDate, endDate);
    } else {
      // Filter out zero-value data and "12:00 AM" for other activities
      processedLineData = lineData.filter(entry => {
        return entry.Measurement !== 0 && entry.Measurement !== "12:00 AM";
      });
    }

      // Limit data points to 15 unless selected range is 'lastMonth'
      if (selectedRange !== 'lastMonth') {
        processedLineData = limitDataPoints(processedLineData, 15);
      }
  
      setTrendBarData(processedLineData);

    // Set display dates for the chart based on processed data
    const displayDates = generateDisplayDates(processedLineData, startDate, endDate, selectedRange);
    setGeneratedDates(displayDates);

  } catch (error) {
    console.error('Error fetching current line graph data:', error);
  }
};

// Function to limit data points to a specified number
const limitDataPoints = (data, maxPoints) => {
  if (data.length <= maxPoints) {
    return data;
  }

  const step = Math.ceil(data.length / maxPoints);
  return data.filter((_, index) => index % step === 0);
};

const generateDisplayDates = (data, startDate, endDate, selectedRange) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const displayDates = [];

  // Iterate over each date from start to end
  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    const formattedDate = format(new Date(date), 'yyyy-MM-dd');
    if (data.some(entry => entry.EntryDate.startsWith(formattedDate))) {
      displayDates.push(getFormattedDate(new Date(date), isLastWeekOrMonth(selectedRange)));
    }
  }

  // Limit display dates to 15 unless selected range is 'lastMonth'
  if (selectedRange !== 'lastMonth') {
    return limitDataPoints(displayDates, 15);
  }

  return displayDates;
};

// Function to generate full range data for Workout activity
const generateFullRangeData = (lineData, startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const formattedLineData = [];

  // Create a map of existing dates in lineData
  const existingDatesMap = lineData.reduce((map, entry) => {
    map[entry.EntryDate] = entry.Measurement;
    return map;
  }, {});

  // Iterate over each date from start to end
  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    const formattedDate = format(new Date(date), 'yyyy-MM-dd');
    if (existingDatesMap.hasOwnProperty(formattedDate)) {
      formattedLineData.push({
        EntryDate: formattedDate,
        Measurement: existingDatesMap[formattedDate]
      });
    } else {
      // Add zero value for dates with no data
      formattedLineData.push({
        EntryDate: formattedDate,
        Measurement: 0
      });
    }
  }

  return formattedLineData;
};

function getFormattedDate(date, includeMonthWords) {
  const day = format(date, 'dd');
  const monthIndex = date.getMonth();
  const year = format(date, 'yyyy');
  if (includeMonthWords) {
    return `${day}-${getMonthValue(monthIndex, true)}`;
  } else {
    return `${day}-${format(date, 'MM')}-${year}`;
  }
}

function getMonthValue(monthIndex, includeWords) {
  if (includeWords) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[monthIndex];
  } else {
    // Return month number (1-based index)
    return monthIndex + 1;
  }
}

function isLastWeekOrMonth(range) {
  return range === 'lastWeek' || range === 'lastMonth';
}

  const chartData = {
    labels: generatedDates,
    datasets: [
      selectedActivity === 'Weight' && {
        label: 'Weight',
        data: trendBarData ? trendBarData.map(entry => entry.Measurement) : [],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        pointBackgroundColor: 'rgb(75, 192, 192)',
        pointBorderColor: 'rgba(75, 192, 192, 0.8)',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
      selectedActivity === 'Workout' && {
        label: 'Workout',
        data: trendBarData ? trendBarData.map(entry => entry.Measurement) : [],
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: 'rgba(255, 99, 132, 0.8)',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
      selectedActivity === 'Breakfast' && {
        label: 'Breakfast',
        data: trendBarData.map(entry => ({
          x: entry.EntryDate,
          y: moment(entry.Measurement, 'HH:mm:ss').toDate()
        })),
        fill: false,
        borderColor: 'rgb(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
        pointBackgroundColor: 'rgb(255, 205, 86)',
        pointBorderColor: 'rgba(255, 205, 86, 0.8)',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
      selectedActivity === 'Education' && {
        label: 'Education',
        data: trendBarData.map(entry => ({
          x: entry.EntryDate,
          y: moment(entry.Measurement, 'HH:mm:ss').toDate()
        })),
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: 'rgba(54, 162, 235, 0.8)',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
      selectedActivity === 'Lunch' && {
        label: 'Lunch',
        data: trendBarData.map(entry => ({
          x: entry.EntryDate,
          y: moment(entry.Measurement, 'HH:mm:ss').toDate()
        })),
        fill: false,
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        pointBackgroundColor: 'rgb(153, 102, 255)',
        pointBorderColor: 'rgba(153, 102, 255, 0.8)',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
      selectedActivity === 'Dinner' && {
        label: 'Dinner',
        data: trendBarData.map(entry => ({
          x: entry.EntryDate,
          y: moment(entry.Measurement, 'HH:mm:ss').toDate()
        })),
        fill: false,
        borderColor: 'rgb(255, 159, 64)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        pointBackgroundColor: 'rgb(255, 159, 64)',
        pointBorderColor: 'rgba(255, 159, 64, 0.8)',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
    ].filter(Boolean),
  };


  const getYAxisLabel = (activity) => {
    switch (activity) {
      case 'Weight':
        return 'kilograms';
      case 'Workout':
        return 'Calories';
      case 'Breakfast':
        return 'Breakfast';
      case 'Lunch':
        return 'Lunch';
      case 'Dinner':
        return 'Dinner';
      case 'Education':
        return 'Education';
      default:
        return '';
    }
  };

  const customTimeParser = (value) => {
    const formats = ['YYYY-HH:mm', 'HH:mm:ss.SSSSSS'];
    return moment(value, formats, true).toDate();
  };

  const generateOptions = (activity, data) => {
    const isTimeBasedActivity = ['Breakfast', 'Education', 'Lunch', 'Dinner'].includes(activity);

    // Default min and max time values
    let minTime = moment().startOf('day').toDate(); // Example: start of today
    let maxTime = moment().endOf('day').toDate();   // Example: end of today

    // Calculate min and max time based on data if available
    if (data && Array.isArray(data) && data.length > 0) {
      minTime = moment.min(data.map(d => moment(d.y, ['YYYY-HH:mm', 'HH:mm:ss.SSSSSS']))).toDate();
      maxTime = moment.max(data.map(d => moment(d.y, ['YYYY-HH:mm', 'HH:mm:ss.SSSSSS']))).toDate();
    }

    return {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM D'
            }
          },
          title: {
            display: true,
            text: 'Date',
            color: '#222',
            font: {
              size: 14,
              weight: 'bold'
            }
          },
          grid: {
            color: 'rgba(200, 200, 200, 0.2)',
            borderDash: [5, 5]
          },
          ticks: {
            color: '#222',
            font: {
              size: 12
            }
          }
        },
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: getYAxisLabel(activity),
            color: "black",
            fontSize: 15,
            fontWeight: "bold"
          },
          type: isTimeBasedActivity ? 'time' : 'linear',
          time: isTimeBasedActivity ? {
            parser: customTimeParser,
            unit: 'minute',
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm',
            },
            min: minTime,
            max: maxTime,
            stepSize: 120
          } : undefined,
          grid: {
            color: 'rgba(200, 200, 200, 0.2)',
            borderDash: [5, 5]
          },
          ticks: {
            color: '#000',
            font: {
              size: 12
            }
          }
        }],
        y: {
          type: 'linear',
          title: {
            display: true,
            text: getYAxisLabel(activity),
            color: '#000',
            font: {
              size: 14,
              weight: 'bold'
            }
          },
          grid: {
            color: 'rgba(200, 200, 200, 0.2)',
            borderDash: [5, 5]
          },
          ticks: {
            color: '#000',
            font: {
              size: 12
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            color: '#000',
            font: {
              size: 12,
              weight: 'bold'
            }
          }
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          titleFont: {
            size: 12,
            weight: 'bold'
          },
          bodyFont: {
            size: 12
          },
          footerFont: {
            size: 10,
            style: 'italic'
          },
          cornerRadius: 4
        }
      }
    };
  };

  // Example usage:
  // const selectedActivity = 'Weight'; // Replace with actual selected activity
  const options = generateOptions(selectedActivity);

  const handleKeyDown = (e) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/', 'Backspace', 'ArrowLeft', 'ArrowRight'];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  //console.log('Breakfast data:', trendBarData);
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .graph-container {
        padding-top: 70px;
        width: 80%;
        margin: 0 auto;
        height: 450px;
      }

      @media (max-width: 768px) {
        .graph-container {
          padding-top: 50px;
          width: 90%;
        }
        .custom-dropdown {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.9rem;
          padding: 8px;
        }
      }

      @media (max-width: 480px) {
        .graph-container {
          padding-top: 30px;
          width: 100%;
          height: 250px; /* Make the graph larger on phone screens */
        }
        .custom-dropdown {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.7rem; /* Reduce font size for custom dropdown */
          padding: 4px; /* Adjust padding for custom dropdown */
        }
        .custom-dropdown label {
          margin-bottom: 4px; /* Reduce margin for labels */
        }
        .select-days {
          font-size: 0.7rem; /* Reduce font size for select element */
        }
        .date-input {
          font-size: 0.7rem; /* Reduce font size for date input */
          width: 80px; /* Adjust width for date input on phone screens */
          padding: 4px; /* Adjust padding for date input */
        }
      }

      .select-days {
        font-size: 1rem;
      }

      .info-container label {
        font-size: 0.9rem;
      }
      .date-input {
        font-size: 0.9rem; /* Default font size for date input */
        width: 100px; /* Default width for date input */
        padding: 10px; /* Default padding for date input */
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);



 //next color graph end --
  return (
    
    //Listing the activities
    <div>
      {/* {inactiveMessage && <p>{inactiveMessage}</p>} */}
      {/* {loading && (
        <div className='load-container'>
          <Spin />
        </div>
      )} */}
      {/* Physical Activities Ahead*/}
      <br></br>


      {/* <select onChange={(e) => setSelectedRange(e.target.value)} value={selectedRange}>
    </select>
    {selectedRange === 'custom' && (
      <div>
        <input type="date" value={customStartDate} onChange={handleCustomStartDateChange} />
        <input type="date" value={customEndDate} onChange={handleCustomEndDateChange} />
      </div>
    )} */}
    
      { showLineGraph && (
        <div className="modal">
          <div className="overlay" onClick={() => { setShowLineGraph(false); setTrendBarData([]); }}></div>
          <div className="graph-content">
          <div className="graph-container" style={{ backgroundColor: '#f4f4f9', padding: '20px', borderRadius: '15px', marginBottom: '20px' }}>
            <Line data={chartData} options={options}/>
          </div>
            <div className='info-container'>
              <select
                value={selectedRange}
                className='select-days'
                style={{ alignSelf: 'center', justifySelf: 'right', marginRight: 25, marginBottom: 5 }}
                onChange={(e) => setSelectedRange(e.target.value)}
              >
                <option value="lastWeek">Last Week</option>
                <option value="lastMonth">Last Month</option>
                <option value="custom">Custom</option>
              </select>
                {selectedRange === 'custom' && (
                <div className="custom-dropdown">
                  <label>Start Date: </label>
                  <DatePicker
                    selected={customStartDate}
                    onChange={date => setCustomStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy" 
                    onKeyDown={handleKeyDown}
                    className="date-input"
                  />
                  <label>End Date: </label>
                  <DatePicker
                    selected={customEndDate}
                    onChange={date => setCustomEndDate(date)}
                    minDate={customStartDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy" 
                    onKeyDown={handleKeyDown}
                    className="date-input"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    

      { modal && (
      <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h3 className='normal-label'>{physicalActivities === 'Weight' ? "Enter today's weight in kg. Last weight entered: " + previousMeasurement + " kg" : "Enter today's workout in kcal. Last kcal burnt: " + previousMeasurement + " kcal"}</h3>
            <input defaultValue={measurement} placeholder={selectedActivityPlaceHolder} onChange={e => {setMeasurement(e.target.value); setShowMeasurementError(false)}}/>
            <button name='input-submit' onClick={()=>{InsertPhysicalActivity(physicalActivities); window.location.reload();}}><FontAwesomeIcon icon={faCheck} style={{ color: 'white', fontSize: '14px' }}/></button>
            {showMeasurementError && (
            !intRegex.test(measurement) ? <p className="error-message">Please enter an valid Input!</p> : <p className="error-message">Total measurement cannot be negative!</p>)}
            </div>
          </div>

)}
{/* { showLineGraph && (
      <div className="modal">
          <div onClick={() => setShowLineGraph(false)} className="overlay"></div>
          <div className="modal-content">
           <LineChart datas={barData} />
          <div className='info-container'>
        <select value={selectedDays} defaultValue={selectedDays} className='select-days' style={{alignSelf: 'center', justifySelf:'right', marginRight: 25, marginBottom: 5}}>
          {NumberOfDays.map(day => {return (<option value={day} key = {day} >{day}</option>)})}
        </select>
        </div>
        </div>      
      </div>
          
)} */}

{/* {stackModal && ( */}
<SideBar />
  {/* )}
      <div style={{marginTop: 10, marginLeft: 30}}>
        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <button onClick={() => {toggleModal("stack")}} style={{width: 'fit-content', alignItems: 'center', justifyContent:'center', border: 0, backgroundColor: 'black', color: 'white',  padding:10}}>
        <WindowIcon /></button>
        <p style={{marginLeft: 10, textTransform: 'capitalize', fontWeight: 400, fontSize: 14}}>Menu</p>
        </span>
      </div> */}
      
      <div className='water-afresh-container'>
        <div style={{ justifySelf: 'center' }}>
      <span>
        <PersonIcon />    
        {/* teamMembers is all members whereas totalTeamMembers is inactive members */}
        <p style={{fontWeight: 'bold', fontSize: 14}}>{teamMembers.length}/{teamMembers.length+totalTeamMembers.length}</p>
        {console.log(totalTeamMembers)}
      </span>
      </div>
      <div style={{justifySelf: 'center',width:150}}>
      
      
      {!idealWeightCrossed && targetWeight && currentWeight !== null && typeof currentWeight === 'number' && (
 <p className="weight-info">
 {currentWeight > targetWeight 
   ? `${(currentWeight - targetWeight).toFixed(2)} kg to reach ideal`
   : `${(targetWeight - currentWeight).toFixed(2)} kg to reach ideal`
 }<br />
 weight
</p>
)}


      {/* <>{idealWeightCrossed && <img src={IdealIcon} alt="Ideal Icon" style={{marginLeft:30 , width:45}}/>}</>   */}
      {idealWeightCrossed && (
        <span style={{display: 'flex', width:200, justifySelf: 'Start'}} >
                  <label className='highlight'>REACHED IDEAL WEIGHT</label>
                  </span>
            )}
            {teamMembers.length === 1 ? 
        <h3 className='normal-label' style={{fontSize: 14, marginTop: 5, textTransform: 'uppercase', letterSpacing: 0, marginRight: 30, marginBottom: 0, justifySelf: 'center' }}>{localStorage.getItem('user')}</h3> :

          <select
        value={selectedMember}
        onChange={(e) => {setSelectedMember(e.target.value);}}
        className='select-member'
        style={{ alignSelf: 'center', justifySelf: 'center',marginLeft:20,marginTop:5, marginBottom: 5 }}
      >
        {teamMembers.map((member, index) => (
          <option value={member} key={index}>
            {member}
          </option>
        ))}
      </select>
      
      
      }
       {targetWeight && <option value="targetWeight" style={{fontSize: 12, fontWeight: 'bold' , padding: 0,marginLeft:30, marginBottom:10}}>Target Weight: {targetWeight} Kgs</option>}

      
      </div>
      </div>
      <div className="list-container">
        {/* <span>
        <button className={postedPhysicalItem.includes("Weight") === true ? (compareTime(timeDataArray["Weight"], "03:00 AM") >= 0 && compareTime(timeDataArray["Weight"], "06:00 AM") <= 0) ? "activity-button-green" : "activity-button" : ""} 
        onClick={() => physicalActivityButtonClick("Weight")} 
        ><FontAwesomeIcon icon={faWeightScale} />&nbsp; &nbsp;{PhyicalActivityList[1]}</button>
        <h3 className='time-label'>{timeDataArray["Weight"]!== "12:00 AM" && timeDataArray["Weight"] !== undefined? 'Posted '+ parseFloat(kgs) +' kg @ ' + timeDataArray["Weight"]:'Post by 3:00 AM-6:00 AM'}</h3>
        </span> */}

        <span>
          <button 
              className={postedPhysicalItem.includes("Weight") === true ? 
                  (compareTime(timeDataArray["Weight"], "03:00 AM") >= 0 && compareTime(timeDataArray["Weight"], "06:30 AM") <= 0) ? 
                      "activity-button-green" : "activity-button" : ""} 
              onClick={() => physicalActivityButtonClick("Weight")} 
              onTouchStart={() => handleButtonHold("lastWeek","Weight")} // Add touch event listener for mobile devices
              onTouchEnd={() => handleButtonRelease()} // Add touch event listener for mobile devices
              onMouseDown={() => handleButtonHold('lastWeek','Weight')} // Add mouse event listener for desktop
              onMouseUp={() => handleButtonRelease()} // Add mouse event listener for desktop
          >
              <FontAwesomeIcon icon={faWeightScale} />&nbsp; &nbsp;{PhyicalActivityList[1]}
          </button>
        <h3 className='time-label'>
            {timeDataArray["Weight"]!== "12:00 AM" && timeDataArray["Weight"] !== undefined ? 
                'Posted '+ parseFloat(kgs) +' kg @ ' + timeDataArray["Weight"] : 'Post by 3:00 AM-6:30 AM'}
        </h3>
        </span>

        <span>
         {/* <button className={kcals > 0 ?  kcals >= 800 ? 'activity-button-green': 'activity-button-orange': ''} 
        onClick={() => physicalActivityButtonClick("Workout")}><FontAwesomeIcon icon={faDumbbell} />&nbsp; &nbsp;{PhyicalActivityList[0]} </button>
         <h3 className='time-label'>{timeDataArray["Workout"]!== "12:00 AM" && timeDataArray["Workout"] !== undefined? parseFloat(kcals) +' kcal as of ' + timeDataArray["Workout"]:''}
         </h3>
            */}
            <button
                className={kcals > 0 ? (kcals >= 800 ? 'activity-button-green' : 'activity-button-orange') : ''}
                onClick={() => physicalActivityButtonClick("Workout")}
                onMouseDown={() => handleButtonHold("lastWeek","Workout")}
                onMouseUp={() => handleButtonRelease()}
                onTouchStart={() => handleButtonHold("lastWeek","Workout")}
                onTouchEnd={() => handleButtonRelease()}
            >
                <FontAwesomeIcon icon={faDumbbell} />&nbsp; &nbsp;{PhyicalActivityList[0]}
            </button>
            <h3 className='time-label'>
                {timeDataArray["Workout"] !== "12:00 AM" && timeDataArray["Workout"] !== undefined ?
                    parseFloat(kcals) + ' kcal as of ' + timeDataArray["Workout"] : ''}
            </h3>
        </span>
        <span>
          <button
          className={postedItem.includes("Breakfast") === true ? (compareTime(timeDataArray["Breakfast"], "05:30 AM") >= 0 && compareTime(timeDataArray["Breakfast"], "08:30 AM") <= 0) ? "activity-button-green" : "activity-button" : ""}
          onClick={() => handleNormalClick("Breakfast")}
          onMouseDown={() => handleButtonHold("lastWeek","Breakfast")}
          onMouseUp={() => handleButtonRelease()}
          onTouchStart={() => handleButtonHold("lastWeek","Breakfast")}
          onTouchEnd={() => handleButtonRelease()}
        >
          <FontAwesomeIcon icon={faPlateWheat} />&nbsp; &nbsp;{Activities[1]}
        </button>
        <h3 className={timeDataArray["Breakfast"] !== "12:00 AM" && timeDataArray["Breakfast"] !== undefined ? 'posted-time-label' : 'time-label'}>
          {timeDataArray["Breakfast"] !== "12:00 AM" && timeDataArray["Breakfast"] !== undefined ?
              'Posted @ ' + timeDataArray["Breakfast"] : 'Post by 5:30 AM-8:30 AM'}
        </h3>
      </span>

        <span>
        {/* <button className={postedItem.includes("Education") === true ? (compareTime(timeDataArray["Education"], "07:15 AM") >= 0 && compareTime(timeDataArray["Education"], "08:45 AM") <= 0) ? "activity-button-green" : "activity-button" : ""}
              onClick={() =>InsertActivity("Education")}><FontAwesomeIcon icon={faBookOpenReader}/>&nbsp; &nbsp;{Activities[0]}</button>
              <h3 className={timeDataArray["Education"]!== "12:00 AM" && timeDataArray["Education"] !== undefined? 'posted-time-label':'time-label'}>{timeDataArray["Education"]!== "12:00 AM" && timeDataArray["Education"] !== undefined?'Posted @ ' +timeDataArray["Education"]:'Post by 7:15 AM-8:45 AM'}</h3>
         */}

            <button
                className={postedItem.includes("Education") === true ? (compareTime(timeDataArray["Education"], "07:15 AM") >= 0 && compareTime(timeDataArray["Education"], "08:45 AM") <= 0) ? "activity-button-green" : "activity-button" : ""}
                onClick={() => handleNormalClick("Education")}
                onMouseDown={() => handleButtonHold("lastWeek","Education")}
                onMouseUp={() => handleButtonRelease()}
                onTouchStart={() => handleButtonHold("lastWeek","Education")}
                onTouchEnd={() => handleButtonRelease()}
            >
                <FontAwesomeIcon icon={faBookOpenReader} />&nbsp; &nbsp;{Activities[0]}
            </button>
            <h3 className={timeDataArray["Education"]!== "12:00 AM" && timeDataArray["Education"] !== undefined ? 'posted-time-label' : 'time-label'}>
                {timeDataArray["Education"] !== "12:00 AM" && timeDataArray["Education"] !== undefined ?
                    'Posted @ ' + timeDataArray["Education"] : 'Post by 7:15 AM-8:45 AM'}
            </h3>

        </span>
        
        <span>
        {/* <button className={postedItem.includes("Lunch") === true ?(compareTime(timeDataArray["Lunch"], "12:00 PM") >= 0 && compareTime(timeDataArray["Lunch"], "2:00 PM") <= 0) ? "activity-button-green" : "activity-button" : ""}
              onClick={() =>InsertActivity("Lunch")}><FontAwesomeIcon icon={faBowlRice}/>&nbsp; &nbsp;{Activities[2]}</button>
              <h3 className={timeDataArray["Lunch"]!== "12:00 AM" && timeDataArray["Lunch"] !== undefined? 'posted-time-label':'time-label'}>{timeDataArray["Lunch"]!== "12:00 AM" && timeDataArray["Lunch"] !== undefined? 'Posted @ ' +timeDataArray["Lunch"]:'Post by 12:00 PM-2:00 PM'}</h3>
         */}

            <button
                className={postedItem.includes("Lunch") === true ? (compareTime(timeDataArray["Lunch"], "12:00 PM") >= 0 && compareTime(timeDataArray["Lunch"], "4:00 PM") <= 0) ? "activity-button-green" : "activity-button" : ""}
                onClick={() => handleNormalClick("Lunch")}
                onMouseDown={() => handleButtonHold("lastWeek","Lunch")}
                onMouseUp={() => handleButtonRelease()}
                onTouchStart={() => handleButtonHold("lastWeek","Lunch")}
                onTouchEnd={() => handleButtonRelease()}
            >
                <FontAwesomeIcon icon={faBowlRice} />&nbsp; &nbsp;{Activities[2]}
            </button>
            <h3 className={timeDataArray["Lunch"] !== "12:00 AM" && timeDataArray["Lunch"] !== undefined ? 'posted-time-label' : 'time-label'}>
                {timeDataArray["Lunch"] !== "12:00 AM" && timeDataArray["Lunch"] !== undefined ?
                    'Posted @ ' + timeDataArray["Lunch"] : 'Post by 12:00 PM-4:00 PM'}
            </h3>
        </span>
        
        <span>
        {/* <button className={postedItem.includes("Dinner") === true ? (compareTime(timeDataArray["Dinner"], "05:30 PM") >= 0 && compareTime(timeDataArray["Dinner"], "08:30 PM") <= 0) ? "activity-button-green" : "activity-button" : "" }
              onClick={() =>InsertActivity("Dinner")}><FontAwesomeIcon icon={faUtensils} />&nbsp; &nbsp;{Activities[3]}</button>   
              <h3 className={timeDataArray["Dinner"]!== "12:00 AM" && timeDataArray["Dinner"] !== undefined? 'posted-time-label':'time-label'}>{timeDataArray["Dinner"]!== "12:00 AM" && timeDataArray["Dinner"] !== undefined?'Posted @ ' +timeDataArray["Dinner"]:'Post by 5:30 PM-8:30 PM'}</h3>
         */}
          <button
                className={postedItem.includes("Dinner") === true ? (compareTime(timeDataArray["Dinner"], "05:30 PM") >= 0 && compareTime(timeDataArray["Dinner"], "08:30 PM") <= 0) ? "activity-button-green" : "activity-button" : ""}
                onClick={() => handleNormalClick("Dinner")}
                onMouseDown={() => handleButtonHold("lastWeek","Dinner")}
                onMouseUp={() => handleButtonRelease()}
                onTouchStart={() => handleButtonHold("lastWeek","Dinner")}
                onTouchEnd={() => handleButtonRelease()}
            >
                <FontAwesomeIcon icon={faUtensils} />&nbsp; &nbsp;{Activities[3]}
            </button>
            <h3 className={timeDataArray["Dinner"] !== "12:00 AM" && timeDataArray["Dinner"] !== undefined ? 'posted-time-label' : 'time-label'}>
                {timeDataArray["Dinner"] !== "12:00 AM" && timeDataArray["Dinner"] !== undefined ?
                    'Posted @ ' + timeDataArray["Dinner"] : 'Post by 5:30 PM-8:30 PM'}
            </h3>
        </span> 
      </div>
      <div className='water-afresh-container'>
      <div className='water-container' style={{ color: 'white', marginLeft: 70 }}>
            <h3 className='normal-label' style={{ color: 'white', fontSize: 14, marginTop: 5, textTransform: 'uppercase', letterSpacing: 0 }}>Water</h3>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <button className='unary-button' onClick={() => {IncreaseDecreaseWaterAfresh('Water', 'Dec')}} name= 'water'> <FontAwesomeIcon icon={faMinus} style={{ color: 'white', fontSize: '14px' }}/> </button>
            <h3 className='normal-label' style={{ color: 'white', fontSize: 14, marginTop: 5, textTransform: 'uppercase' }}>{waterLtrs}</h3>
            <button className='unary-button' onClick={() => {IncreaseDecreaseWaterAfresh('Water', 'Inc')}} name= 'water'> <FontAwesomeIcon icon={faPlus} style={{ color: 'white', fontSize: '14px' }}/> </button>
          </div>
          <h3 className='normal-label' style={{ color: 'white', fontSize: 14, marginTop: 5, textTransform: 'uppercase', letterSpacing: 0 }}>(in Litre) </h3>
        </div>
        <div className='afresh-container' style={{ color: 'white', marginLeft: 45 }}>
          <h3 className='normal-label' style={{ color: 'white', fontSize: 14, marginTop: 5, textTransform: 'uppercase', letterSpacing: 0 }}>Afresh</h3>
          <div style={{ display: 'flex' , justifyContent: 'center'}}>
            <button className='unary-button' onClick={() => {IncreaseDecreaseWaterAfresh('Afresh', 'Dec')}}>
            <FontAwesomeIcon icon={faMinus} style={{ color: 'white', fontSize: '14px' }}/>
            </button>
            <h3 className='normal-label' style={{ color: 'white', fontSize: 14, marginTop: 5, textTransform: 'uppercase' }}>{afresh}</h3>
            <button className='unary-button' onClick={() => {IncreaseDecreaseWaterAfresh('Afresh', 'Inc')}}>
            <FontAwesomeIcon icon={faPlus} style={{ color: 'white', fontSize: '14px' }}/>
            </button>
          </div>
          <h3 className='normal-label' style={{ color: 'white', fontSize: 14, marginTop: 5, textTransform: 'uppercase', letterSpacing: 0 }}>(in Scoop) </h3>
        </div>
      </div>
        
      {/* Measuring the time Discipline*/}
      <div className='info-container'>
        {/* <span>
          <h3 className='normal-label' style={{fontSize: 14, marginTop: 5, textTransform: 'uppercase', letterSpacing: 0, marginLeft: 20, marginBottom: 0 }}>{thisDay} <br></br>Version: 2.3.4</h3>
        </span> */}
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <span>
        {/* <button name='logout-btn'  style={{fontSize: 14}}onClick={Logout}><FontAwesomeIcon icon={faSignOutAlt} style={{ color: 'white', fontSize: '14px' }} />Logout</button> */}
        
        </span>
        </div>
      </div>
      <div className='info-container'>
        <h3 className='overall-label' style = {{marginLeft: 20, marginBottom: 5, marginTop: 0, justifySelf:'center'}}>Overall %</h3>
        <select value={selectedDays} onChange={e => setSelectedDays(e.target.value)} defaultValue={selectedDays} className='select-days' style={{alignSelf: 'center', justifySelf:'left', marginRight: 25, marginBottom: 5}}>
          {NumberOfDays.map(day => {return (<option value={day} key = {day} >{day}</option>)})}
        </select>
      </div>
      <div className='score-container'>
        <span>
          <span>
          <h3 className='normal-label' style={{fontSize: 12,  letterSpacing: 0, textAlign: 'start', marginLeft: 40}}>Workout: {overallKcal["Total"]}({overallKcal["Average"]})</h3>
          <h3 className='normal-label' style={{fontSize: 16,  letterSpacing: 0, textAlign: 'start', marginLeft: 40}}>{totalTimeDiscipline}{teamMembers.length === 1 ? '' : <span style={{ color: 'blue' }}>(Team: {totalTeamDiscipline})</span>}</h3>
          </span>
        <BarChart datas={barData} title='Overall %'/>
        </span>
        <span>
          <span>
          <h3 className='normal-label' style={{fontSize: 12, letterSpacing: 0, textAlign: 'left', marginLeft: 40}}>Workout: {selectedKcal["Total"]}({selectedKcal["Average"]})</h3>
          <h3 className='normal-label' style={{fontSize: 16, letterSpacing: 0, textAlign: 'left', marginLeft: 40}}>{avgFewDaysDiscipline}{teamMembers.length === 1 ? '' : <span style={{ color: 'blue' }}>(Team: {teamDiscipline})</span>}</h3>
          </span>
          <BarChart datas={fewDaysTimeDiscipline} title = {selectedDays + ''} />
        </span>
        {/* <CircleProgressBar percentage={totalTimeDiscipline.substring(0, totalTimeDiscipline.indexOf("%"))} circleWidth="160"/> 
       <CircleProgressBar percentage={fewDaysTimeDiscipline.substring(0, fewDaysTimeDiscipline.indexOf("%"))} circleWidth="160"/>  */}
      </div>
      </div>
  
  
  );
}

//a horizontal line
// function HorizontalLine() 
// {
//   return (
//     <div className="hr">
//     <span></span>
//     </div>
//   );
// }

//function for vibrating the mobile
function vibrate() 
{
  if (!window) 
  {
    return;
  }    
  if (!window.navigator) 
  {
    return;
  }
  if (!window.navigator.vibrate) 
  {
    return;
  }
  window.navigator.vibrate(100);
}


export default Main;