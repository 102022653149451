import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function BarChart({ datas, title }) {
  const BarData = datas;
  const data = {
    labels: ['Weight', 'Education', 'Breakfast', 'Lunch', 'Dinner'],
    datasets: [
      {
        label: '',
        data: [
          BarData['Weight'],
          BarData['Education'],
          BarData['Breakfast'],
          BarData['Lunch'],
          BarData['Dinner'],
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 205, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(201, 203, 207, 1)'
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Set to false to allow resizing
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false
    }
  };

  return (
    <div style={{ width: '170px', height: '200px', margin: '0 auto'}}>
      <Bar data={data} options={options} />
    </div>
  );
}
