import React, { useEffect, useState } from 'react';
import logo from '../Images/BNutritions Logo.png';
import '../css/Login.css';

import { message, Spin } from 'antd';
import { api } from '../API/FetchAllDetailsAPI';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import  Divider  from '@mui/material/Divider';
import { Link } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Login () {
  const [userNameInput, setUserNameInput] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleShowPassword = () => {setShowPassword(!showPassword)};

  const passwordIcon = showPassword ? <faEyeSlash onClick={toggleShowPassword} /> : <faEyeSlash onClick={toggleShowPassword} />;

  function toProperCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  useEffect(() => {
    if(localStorage.getItem('user')){
      window.location.reload()
    }
  },[])
//   useEffect(() => {
//     document.body.style.zoom = "100%";
//   }, []);

  const checkUserDetails = async(event) => {
    setLoading(true);
    event.preventDefault();
      if(userNameInput.trim() === '' || password === ''){
        setTimeout(() => {
          message.info("Please Enter User Name and Password")
          setLoading(false);
        }, 1000);
        
        return;
      }
      try{
       const encodedPassw = encodeURI(password)
        const response = await api.get('/Login.php/get',{
          params:{
            JsonUserName: userNameInput,
            JsonPassword: encodedPassw
          }
        })
        if(response.data === 'Login Succesfully'){
          localStorage.setItem('user', toProperCase(userNameInput.trim()))
          //onLogin(userNameInput.trim());
          window.location.reload()
          //navigate('/')
          //navigation.navigate('Main', { userNameInput }); 
        }else{
          if(response.data === "Inactive Member"){
            alert("You are an Inactive User. Contact your coach to login ")
          } else{
            setTimeout(() => {
              message.error("Wrong Password!")
            })
          }
          setLoading(false);
        } 
    } catch(error){
      console.error(error)
  }}

  const handleChange = (e) => {
      setUserNameInput(e.target.value.trim());
  }

  return (
    <div className=" main    flex items-center justify-center min-h-screen  p-1">   
      
        <form onSubmit={checkUserDetails} className="w-[85%] sm:w-[384px] login-form bg-slate-50 p-1 rounded-2xl drop-shadow-xl  ">  
          <div className=" p-4  rounded-xl">
           
          <div className="flex flex-col items-center justify-center mb-3 ">
            <img src = {logo} className="profile w-24 h-24  mb-3 " alt = 'profile' /> {/*sm:w-44 sm:h-*/}
       <div className="text-center sm:text-center  ">
         <div className='flex gap-2'>
              <h1 className='text-3xl font-semibold   font-cormorant text-pink-400 '>BALEEN</h1>
             <h1 className="text-3xl font-semibold  font-cormorant text-green-800 ">  NUTRITIONS</h1>
             </div>
             <p className="text-sm   mb-3 ">V CARE FOR U</p>
              <Divider/>
           </div> 

        </div>
        <div className="mb-6">
        {/* <label htmlFor="userNameInput" className="block text-xl sm:text-3xl font-semibold text-gray-700 ">Username</label> */}
            <input 
            //style={{width: '240px'}}
            id='usernameInput'
            name = 'usernameInput'
            value={userNameInput}
            placeholder = " Username"
            onChange={handleChange}
             maxLength = {12}
             className="mt-2  p-5  w-full opacity-70 font-normal text-base  rounded-2xl drop-shadow-lg shadow-md hover:shadow-xl border-transparent focus:border-transparent focus:shadow-none "
            />
          </div>  
        <div/>
         <div className="mb-6">
          {/* <label htmlFor="inputPassword" className=" text-xl sm:text-3xl font-semibold text-gray-700"> Password </label> */}
          <div className=' relative '>
            <input
            id = 'inputpassword'
            name='inputpassword'
            className="mt-2 p-5   w-full opacity-70 font-normal text-base  rounded-2xl drop-shadow-lg shadow-md hover:shadow-xl border-transparent focus:border-transparent focus:shadow-none "
              defaultValue={password}
              placeholder = "Password"
              type={showPassword ? "text" : "password"}
              onChange={e => setPassword(e.target.value)}
            
           iconRender={() => passwordIcon}
            >
              

              </input>
              <div className="absolute top-6 right-2  mt-2 mr-3" >
              <FontAwesomeIcon icon={faEyeSlash} className='text-slate-500 sm:size-6 cursor-pointer' onClick={toggleShowPassword}/>
              </div>
              </div>
          </div>
         <div className="flex flex-col items-center justify-between mb-4 ">
         <div>
         <Link to="/ChangePassword" className="font-medium text-gray-400 hover:text-gray-500 text-sm no-underline">Change Password?</Link>

           </div>
            <br/>
            <button type="submit" className=" w-3/4  bg-green-500 hover:bg-green-700 text-white font-bold py-2  px-4  rounded-xl text-lg border-none">Login</button>
          </div>
          
          
          
          {loading && 
            <div className='load-container mt-1 '>
              <Spin />
            </div>
          }
          
          
        </div>
        </form>
      </div>
    
  );
}

export default Login; //Test site changed