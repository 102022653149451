import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LockIcon from '@mui/icons-material/Lock';

           

export const SideBarData = [
    {
        title: 'Main',
        icon: <HomeIcon />,
        link: '/'
    },
    {
        title: 'Profile',
        icon: <AccountBoxIcon />,
        link: '/profile'
    },
    {
        title: 'Change Password',
        icon: <PasswordIcon />,
        link: '/changepassword'
    },
    {
        title: 'Report',
        icon: <AssessmentIcon />,
        link: '/Report'
    },
    {
        title: 'Inactive Members',
        icon: <LockIcon />,
        link: '/UnlockUsers'
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        link: '/'
    }
    
]

