import React, { useState, useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'; 
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { useFormik } from 'formik';
import { Checkbox } from 'primereact/checkbox'; // Import Checkbox component
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { fetchCoachInactiveTeamMembers, changeUserStatus, AddNewUsers } from '../API/FetchAllDetailsAPI';
import '../css/UnlockUsers.css';
import * as Yup from 'yup'; // Import Yup for validation

const UnlockUsers = () => {
    const [state, setState] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]); // State to keep track of selected users
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            username: '',
            password: 'User@123#',
            coach: localStorage.getItem('user'),
            coCoach: '',
            targetWeight: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Username is required').matches(/^[a-zA-Z]+$/, 'Username cannot contain numbers or spaces'),
            password: Yup.string().required('Password is required'),
            coCoach: Yup.string().required('Co-Coach Name is required').matches(/^[a-zA-Z]+$/, 'Co-Coach Name cannot contain numbers or spaces'),
            // targetWeight: Yup.number().required('Target weight is required').positive('Target weight must be a positive number'),
        }),
        onSubmit: async (values) => {
            try {
                const entryUser = localStorage.getItem('user');
                const result = await AddNewUsers(values, entryUser);
              
                // Inspect the structure of the result
                if (result?.message.includes('Duplicate entry')) {
                  alert('The username is already taken. Please choose a different username.');
                } else if (result?.message) {
                  alert(result.message);
                  formik.resetForm();
                } else {
                  alert('User created successfully!');
                  formik.resetForm();
                }
              } catch (error) {
                console.error('Failed to create user:', error.message);
              
                // Check if the error message contains "Duplicate entry"
                if (error.message.includes('Duplicate entry')) {
                  alert('Failed to create user: The username is already in use.');
                } else {
                  alert(`Failed to create user: ${error.message}`);
                }
              }
              
        },
    });

    useEffect(() => {
        fetchInactiveTeamMembers();
    }, []);

    const fetchInactiveTeamMembers = async () => {
        try {
            const membersList = await fetchCoachInactiveTeamMembers(localStorage.getItem('user'));
            setState(membersList);
        } catch (error) {
            console.error('Error fetching weight data:', error);
        }
    }

    const handleSetActive = async () => {
        if (selectedUsers.length > 0) {
            try {
                selectedUsers.forEach(async (user) => { await changeUserStatus(user); 
                });
                window.location.reload();
            } catch (error) {
                console.error('Error setting user active:', error);
            }
        } else {
            console.log('No user selected.');
        }
    };

    

    const mappedData = state.map((item, index) => ({
        SNo: index + 1,
        Name: item,
        Checkbox: <Checkbox onChange={(e) => handleCheckboxChange(e, item)} checked={selectedUsers.includes(item)} />
    }));

    const handleCheckboxChange = (e, item) => {
        const checked = e.checked;
        if (checked) {
            setSelectedUsers([...selectedUsers, item]); // Add user to selectedUsers array
        } else {
            setSelectedUsers(selectedUsers.filter(user => user !== item)); // Remove user from selectedUsers array
        }
    };

    const handleSelectAll = (e) => {
        const checked = e.checked;
        if (checked) {
            setSelectedUsers(state); // Select all users
        } else {
            setSelectedUsers([]); // Deselect all users
        }
    };


    return (
        <div className="Home-nav">
    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' , marginLeft: 10, padding: 12}}>
        <button onClick={() => navigate('/')} style={{ width: 'fit-content', alignItems: 'center', justifyContent: 'center', border: 0, backgroundColor: 'black', color: 'white', padding: 10, cursor: 'pointer' }}>
            <HomeIcon />
        </button>
        <p style={{ marginLeft: 8, textTransform: 'capitalize', fontWeight: 400, fontSize: 14, color: '#007E60' }}>Home</p>
    </span>
   <div className="new-user-form">
            <h1>Create New User</h1>
            <form onSubmit={formik.handleSubmit}>
                <FloatLabel >
                    <InputText 
                        id="username"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="username">Username</label>
                    {formik.touched.username && formik.errors.username ? (
                        <div className="error">{formik.errors.username}</div>
                    ) : null}
                </FloatLabel>
                <FloatLabel label="Password" htmlFor="password">
                    <InputText
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        
                    />
                    <label htmlFor="password">Password</label>
                    {formik.touched.password && formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                    ) : null}
                </FloatLabel>
                <FloatLabel label="Coach" htmlFor="coach">
                    <InputText 
                        id="coach"
                        name="coach"
                        value={formik.values.coach}
                        onChange={formik.handleChange}
                        disabled 
                    />
                    <label htmlFor="coach">Coach</label>
                    
                </FloatLabel>
                <FloatLabel label="Co-Coach" htmlFor="coCoach">
                    <InputText 
                        id="coCoach"
                        name="coCoach"
                        value={formik.values.coCoach}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="coCoach">Co-Coach</label>
                    {formik.touched.coCoach && formik.errors.coCoach ? (
                    <div className="error">{formik.errors.coCoach}</div>
                ) : null}
                </FloatLabel>
                {/* <FloatLabel label="Target Weight" htmlFor="targetWeight">
                    <InputText 
                        id="targetWeight"
                        name="targetWeight"
                        type="number"
                        value={formik.values.targetWeight}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="targetWeight">Target Weight</label>
                    {formik.touched.targetWeight && formik.errors.targetWeight ? (
                    <div className="error">{formik.errors.targetWeight}</div>
                ) : null}
                </FloatLabel> */}
                <Button type="submit" label="Create User" className="p-mt-1" />
            </form>
        </div> 
    <h1 style={{fontSize: 18, marginLeft: '18px', color: '#007E60'}}>Inactive Members</h1>

        <div className="card">
            <DataTable 
                value={mappedData}  
                className="data-table"  
                style={{marginLeft: '18px', width: '90%', borderRadius: '8px', overflow: 'hidden'}}
                selectionMode="multiple" // Enable multiple selection
                selection={selectedUsers} // Bind selectedUsers array to the selection
                onSelectionChange={(e) => setSelectedUsers(e.value)} // Update selectedUsers array on selection change
            >
                <Column
                field="Checkbox"
                header={
                    <div style={{ marginLeft: '7px' }}>
                    <Checkbox
                        onChange={handleSelectAll}
                        checked={selectedUsers.length === state.length}
                    />
                    </div>
                    }
                style={{ width: '3em' }}
                />
                <Column field="SNo" header="SNo"></Column>
                <Column field="Name" header="Inactive Users"></Column>
            </DataTable>
            <Button 
                label="Approve" 
                className="custom-button" 
                onClick={handleSetActive} 
                disabled={selectedUsers.length === 0} // Disable button if no user is selected
                style={{width: '100px',marginLeft: '18px', fontSize: '12px', height: '35px'}}
            />
        </div>
        </div>
    );
}

export default UnlockUsers;
