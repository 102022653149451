import { message } from "antd";

export function showErrorMessage(messageContent) {
  message.error(messageContent)
}

function showMessageInfo(messageContent) {
  message.info(messageContent)
};

export function showWarningMessage(messageContent) {
  message.warning(messageContent)
}

export default showMessageInfo;