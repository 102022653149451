import React from 'react';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Main from './Pages/Main';
import Login from './Pages/Login';
import ChangePassword from './Pages/ChangePassword';
import UpdateProfile from './Pages/UpdateProfile';
import Report from './Pages/Report';
import UnlockUsers from './Pages/UnlockUsers';
// import  Test  from './Pages/Test';
// import LoginTest from './Pages/LoginTest'
function App() {
    const loggedInUser = localStorage.getItem('user');
    
    return (
        <BrowserRouter basename='/'>
            <Routes>
                <Route
                    path="/"
                    element={ loggedInUser ? <Main /> : <Login /> }
                />
                {/* The next line is very important for the Navigate component to work */}
                {/* <Route
                    path="/login"
                    element={<Login />}
                /> */}
                <Route
                    path='/profile'
                    element={<UpdateProfile />}
                />
                {/* <Route
                    path='/Test'
                    element={<Test />}
                />
                <Route
                    path='/LoginTest'
                    element={<LoginTest />}
                /> */}
                <Route
                    path='/ChangePassword'
                    element={<ChangePassword />}
                />
                <Route 
                    path = '/Report'
                    element={<Report/>}
                />
                <Route 
                    path = '/UnlockUsers'
                    element={<UnlockUsers/>}
                />
                
            </Routes>
        </BrowserRouter>
    );
}
export default App;